import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import PMid from '../components/PMid'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'
import { useEffect } from 'react'

export default function PedMedDev() {
  useEffect(() => {
    document.title = 'PedMedDev'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="PedMedDev"
        link1name=""
        link1target=""
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>PedMedDev</H2>
      <H3>The Hub for Pediatric Medical Devices</H3>
      <PMid>
        The overall goal of the PedMedDev Hub is to promote and
        improve medical devices for our children.
      </PMid>
      <PSmall>
        Regulatory requirements, high development costs, a small
        market share and insufficient remuneration hinder the
        development of child-centered medical technology, although it
        is precisely they, and with them society, that would benefit
        most.
      </PSmall>
      <PSmall>
        The PedMedDev Hub is the hub for linking people, resources and
        infrastructures. By combining synergistically the potential of
        all stakeholders like patients, clinicians, scientists,
        engineers and medical device manufacturers , the
        above-mentioned hurdles in the development of medical devices
        for children can be overcome. This includes the identification
        and evaluation of clinical needs and the design and
        development of creative solutions and processes as well as the
        transfer to the clinic and thus to the patient - both with
        national and European reach.
        <Img
          fullwidth
          target="./assets/images/pedmeddev/pedmeddev1.png"
        />
      </PSmall>
      <PSmall>
        The PedMedDev Hub will intensify the existing activities and
        realize the network of competencies to enable strategic
        initiatives to accelerate safe, efficient and cost-effective
        innovation.
      </PSmall>
      <PSmall>
        In particular, the current new technologies (e. g. 3D
        printing, rapid prototyping, digitalization in healthcare, AI,
        sensor technology, nano devices) are creating major
        opportunities for paediatric medical devices.
        <Img
          fullwidth
          target="./assets/images/pedmeddev/pedmeddev2.png"
        />
      </PSmall>
      <PSmall>
        The PedMedDev - Project is supported by the Damp Stiftung.
      </PSmall>
      <H3>Health Care Research “Pediatric Medical Devices”</H3>
      <PSmall>
        The memebers of the HUB are conducting as a multidisciplinary
        team health policy and systems research (HPSR). They are using
        qualitative and quantitative methods to ask questions about
        pediatric medical devices. The views of all stakeholders
        involved and the legal framework are examined and analyzed. We
        are working about child centricity, clinical needs, customer
        discovery, ethical issues, patient rights, effectiveness and
        efficiency, scientific evidence, costs, quality and safety.
      </PSmall>
      <H3>Innovation &amp; Development</H3>
      <PSmall>
        An active research group, which are innovating and developing
        for kids. The community are doing prototyping, preclinical and
        clinical research
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          Dr. med. Ludger Tüshaus
          <br />
          Senior doctor
          <br />
          specialist for pediatric surgery, specialist for pediatric
          and adolescent medicine
          <br />
          <a href="mailto:ludger.tueshaus@uksh.de">
            ludger.tueshaus@uksh.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
