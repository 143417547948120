import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function AILab() {
  useEffect(() => {
    document.title = 'AI Lab Lübeck'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="AI Lab Lübeck"
        link1name="Website"
        link1target="https://ai-lab.digital-hub-luebeck.de/"
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>AI Hub Lübeck</H2>
      <H3>
        AI LAB Lübeck is a project by the University of Lübeck,
        sponsored by German Ministry for Education and Research.
      </H3>
      <PSmall>
        The main focus of our project is to develop an infrastructure
        allowing its users to perform research and gain qualification
        in the area of Artificial Intelligence. We provide software
        engineering tools to foster an easy development process and
        realization of AI-based solutions and build up an
        infrastructure for continuous development of secure AI-based
        systems on different edge platforms.
      </PSmall>
      <PSmall>
        We also offer a comprehensive educational and qualification
        program for undergraduate and postgraduate students,
        researchers and industrial scholars.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/aihub/leucker.jpg" />
          Prof. Dr. Martin Leucker
          <br />
          Director of the Institute for Software Engineering and
          Programming Languages
          <br />
          AI LAB LÜBECK
          <br />
          University of Lübeck – Building MFC II
          <br />
          Maria-Goeppert-Str. 3<br />
          23562 Lübeck
          <br />
          <a href="mailto:ki-lab@digital-hub-luebeck.de">
            ki-lab@digital-hub-luebeck.de
          </a>
          <br />
          <a href="tel:+49 451 3101 6500">+49 451 3101 6500</a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
