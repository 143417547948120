import styled from 'styled-components/macro'
import { Color } from '../styles/Variables'

export default function FactElement(props) {
  return (
    <Wrapper>
      {props.facticon}
      <FactHeadline>
        {props.factnumber}
        <br />
        {props.factdescription}
      </FactHeadline>
      <FactInfo>{props.factinfo}</FactInfo>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${props => props.theme.breakpoints.m} {
    text-align: center;
  }

  @media ${props => props.theme.breakpoints.s} {
    text-align: center;
  }
`

const FactHeadline = styled.p`
  padding: 0.75rem 0;
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 2.25rem;
  color: ${Color.hicnight};
`

const FactInfo = styled.p`
  font-size: 1.375rem;
  line-height: 1.625rem;
  color: ${Color.hicdaydarker};
  white-space: pre-line;
`
