import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function GruenderCube() {
  useEffect(() => {
    document.title = 'GründerCube'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="GründerCube"
        link1name="Website"
        link1target="https://gruendercube.de"
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>GründerCube</H2>
      <PSmall>
        GründerCube is the science based start-up consultancy of the
        University of Lübeck and the Technische Hochschule Lübeck. We
        assist students, employees, scientists as well as other
        founders in Schleswig-Holstein on their way of founding
        innovative companies. - From idea and concept creation to
        market evaluation and business model building. Hereby we
        support founders with a broad approach, which includes
        different workshops and lecturers.
      </PSmall>
      <PSmall>
        We are named one of the “Exist Gründerhochschulen” in Germany.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/gruendercube/gruendercube3.png"
          />
          Thies Hauke Mahn <br />
          start-up consultant
        </Li>
        <Li>
          <a href="tel:+49 451 3101 4910">+49 451 3101 4910</a>
          <br />
          <a href="mailto:thies.mahn@uni-luebeck.de">
            thies.mahn@uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/gruendercube/gruendercube4.png"
          />
          Benjamin Peters
          <br />
          start-up consultant
          <br />
          <a href="tel:+49 451 3101 5922">+49 451 3101 5922</a>
          <br />
          <a href="mailto:benjamin.peters@th-luebeck.de">
            benjamin.peters@th-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
