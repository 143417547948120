import styled from 'styled-components/macro'

export const StartYourResearchSection = styled.section`
  grid-column: 6 / span 8;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: max-content;
  gap: ${props =>
    props.gapDefault ? props.gapDefault : '2rem 2rem'};
  margin: ${props => (props.marginDefault ? props.marginDefault : 0)};

  @media ${props => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(2, 1fr);
    gap: ${props =>
      props.gapMedium ? props.gapMedium : '1rem 1rem'};
    margin: ${props => (props.marginMedium ? props.marginMedium : 0)};
  }

  @media ${props => props.theme.breakpoints.s} {
    grid-column: 2 / 6;
    grid-template-columns: repeat(1, 1fr);
    gap: ${props => (props.gapSmall ? props.gapSmall : '1rem 1rem')};
    margin: ${props => (props.marginSmall ? props.marginSmall : 0)};
  }
`
