import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function SasbSoft() {
  useEffect(() => {
    document.title = 'SAS-BSOFT Workflow'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="SAS-BSOFT Workflow"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>SAS-BSOFT Workflow</H2>
      <H3>
        Curation and Development of Databases for Small Angle Data in
        Structural Biology and Soft Matter
      </H3>
      <PSmall>
        The SAS-BSOFT project aims to quantify and curate large
        amounts of experimental data generated in large-scale research
        facilities in an AI-assisted and automated approach. The data
        will then be added to the already existing SABDB database and
        subsequently made available to all scientists following the
        FAIR principles.
        <Img fullwidth target="./assets/images/sasbsoft/image.jpg" />
      </PSmall>
      <PSmall>
        Funding: Bundesministerium für Bildung und Forschung
        <br />
        <br />
        Project partner: European Molecular Biology Laboratory (EMBL)
        Hamburg Outstation
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/sasbsoft/portrait1.jpg"
          />
          Siawosch Schewa
          <br />
          Research Assistant
          <br />
          <a href="mailto:siawosch.schewa@th-luebeck.de">
            siawosch.schewa@th-luebeck.de
          </a>
        </Li>
        <Li>
          <Img
            portrait
            target="./assets/images/sasbsoft/portrait2.jpg"
          />
          Prof. Dr. Manfred Rößle
          <br />
          Laboratory for X-ray Engineering
          <br />
          <a href="mailto:siawosch.schewa@th-luebeck.de">
            siawosch.schewa@th-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
