import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import CardElement from '../elements/CardElement'

export default function LabStructures() {
  return (
    <Wrapper>
      <BoxWrapper sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="HANSE INNOVATION CAMPUS LÜBECK"
          text="who we are"
          linktarget="/Hic"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaymedium
          headline="FabLab"
          text=""
          linktarget="/FabLab"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="AI-Lab"
          text=""
          linktarget="/AILab"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="OLRIM"
          text="Open Lab for Robotics and Imaging in Industry and Medicine"
          linktarget="/OLRIM"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="Further Education - CARAQA"
          linktarget="/Caraqa"
          linktext="Learn more"
        />
      </BoxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: transparent;
`

const BoxWrapper = styled(Row)`
  background: transparent;
`
