import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import PSmall from '../components/PSmall'
import Logo from '../elements/Logo'

export default function Pasbadia() {
  useEffect(() => {
    document.title = 'PASBADIA'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="PASBADIA"
        link1name="YouTube"
        link1target="https://www.youtube.com/embed/s9Ole9NynBw"
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>PASBADIA</H2>
      <H3>
        Patient-centric smartphone-based diagnostics with local and
        central AI platform for primary care in rural areas
      </H3>
      <PSmall>
        Medicine often requires rapid, near-patient diagnosis. For
        example, monitoring the curative course of patients with
        mobility impairments in rural areas. In poorly accessible or
        under-served areas, the application of readily-available
        diagnostic equipment is a promising approach to improve access
        to medical care. Current smartphones are suitable for this
        task due to their widespread use, built-in sensor technology,
        and computing capacity. High-quality cameras, together with
        light sources (LED-flash) built into smartphones enable
        optical diagnostics. Therefore, PASBADIA develops appropriate
        attachments and applications for smartphones where measurement
        data is evaluated directly on-site. In this project, the
        successful competence centre TANDEM (Technology and
        Engineering in Medicine) and COPICOH join their expertise in
        medicine and medical technology.
      </PSmall>
      <H3>General Objective</H3>
      <PSmall>
        For four years, doctoral research associates develop solutions
        together with master’s students for ophthalmological
        examinations in general medicine. They enable smartphones by
        attachments for this task where otherwise special devices
        would be required. They investigate to which extent a family
        doctor can support the care of eye diseases close to home with
        a diagnostic tool with artificial intelligence (AI). The aim
        of the project is improvement of ophthalmological care in
        rural areas.
      </PSmall>
      <PSmall>
        The{' '}
        <a href="https://www.joachim-herz-stiftung.de/was-wir-tun/foerderung/foerderbereich-ingenieurwissenschaften/pasbadia-patientennahe-smartphone-basierte-diagnostik/">
          Joachim Herz Foundation
        </a>{' '}
        in Hamburg funds PASBADIA with 1.3 million Euro. 
      </PSmall>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
