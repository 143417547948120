import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import {
  Color,
  Headline
} from '../styles/Variables'
// import Links from './Links'

export default function PageHeadline({
  children
}) {
  return (
    <Wrapper as="div">
      {/* <Links /> */}
      <H2Styled
        as="h2"
        sd={5}
        ed={7}
        sm={2}
        em={6}
        ss={2}
        se={2}>
        {children}
      </H2Styled>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 4rem 0;
`

const H2Styled = styled(Row)`
  ${Headline.H2};
  margin: 0;
  padding: 0;
  color: ${Color.hicnight};
  text-align: left;
  text-transform: uppercase;
`
