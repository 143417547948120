import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { StartYourResearchSection } from '../layout/StartYourResearchSection'
import InstituteBox from '../elements/InstituteBox'
import { Color, Headline } from '../styles/Variables'
import { GroupIcon } from '../assets/Icons'

export default function StartYourResearch() {
  return (
    <Wrapper>
      <HeadlineBlock sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <BigIcon>{GroupIcon}</BigIcon>
        <InstituteHeadlineStyled>
          Start <br />
          your research with us
        </InstituteHeadlineStyled>
      </HeadlineBlock>
      <ContentBlock>
        <ContentBox sd={1} ed={2} sm={1} em={2} ss={2} se={2}>
          <InstituteBox
            headline="University of Lübeck"
            description=""
            target="http://www.uni-luebeck.de"
          />
        </ContentBox>
        <ContentBox sd={3} ed={2} sm={3} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Technische Hochschule"
            description=""
            target="http://www.th-luebeck.de"
          />
        </ContentBox>
        <ContentBox sd={5} ed={2} sm={1} em={2} ss={2} se={2}>
          <InstituteBox
            headline="University Medical Center Schleswig-Holstein, Campus Lübeck"
            description=""
            target="http://www.uksh.de"
          />
        </ContentBox>
        <ContentBox sd={1} ed={2} sm={3} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Fraunhofer IMTE"
            description=""
            target="http://www.imte.fraunhofer.de"
          />
        </ContentBox>
        <ContentBox sd={3} ed={2} sm={1} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Fraunhofer MEVIS, Office Lübeck"
            description=""
            target="http://www.mic.uni-luebeck.de/fraunhofer-mevis-in-luebeck.html"
          />
        </ContentBox>
        <ContentBox sd={5} ed={2} sm={3} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Technik&shy;zentrum Lübeck"
            description=""
            target="http://www.tzl.de"
          />
        </ContentBox>
        <ContentBox sd={1} ed={2} sm={1} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Medical Laser Center Lübeck"
            description=""
            target="http://www.mll-luebeck.com"
          />
        </ContentBox>
        <ContentBox sd={3} ed={2} sm={3} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Uni&shy;Transfer&shy;Klinik GmbH"
            description=""
            target="http://www.unitransferklinik.de"
          />
        </ContentBox>
        <ContentBox sd={5} ed={2} sm={1} em={2} ss={2} se={2}>
          <InstituteBox
            headline="oncampus GmbH"
            description=""
            target="http://www.oncampus.de"
          />
        </ContentBox>
        <ContentBox sd={1} ed={2} sm={3} em={2} ss={2} se={2}>
          <InstituteBox
            headline="Hanse Innovation Campus Lübeck"
            description=""
            target="http://www.hic-luebeck.de"
          />
        </ContentBox>
      </ContentBlock>
    </Wrapper>
  )
}

const InstituteHeadlineStyled = styled.h2`
  ${Headline.H2}
  color: ${Color.hicnight};
  text-transform: uppercase;
`

const BigIcon = styled.div`
  margin: 0 0 1rem 0;
`

const Wrapper = styled(Grid)`
  margin: 7.75rem auto;
  padding: 5rem 0;
  background: ${Color.hicbutteryellow};
`

const HeadlineBlock = styled(Row)`
  background: transparent;
`

const ContentBlock = styled(StartYourResearchSection)`
  background: transparent;
`

const ContentBox = styled(Row)`
  @media ${props => props.theme.breakpoints.m} {
    margin-bottom: 1rem;
  }

  @media ${props => props.theme.breakpoints.s} {
    margin-bottom: 1rem;
  }
`
