import styled from 'styled-components/macro'

import Header from '../components/Header'
import FactSection from '../components/FactSection'
import SectionHeadline from '../components/SectionHeadline'
import Projects from '../subpages/Projects'
import Centers from '../subpages/Centers'
import StartYourResearch from '../components/StartYourResearch'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import LabStructures from '../subpages/LabStructures'
import HealthtecStartUps from '../subpages/HealthtecStartUps'
import { useEffect } from 'react'

export default function App() {
  useEffect(() => {
    document.title = 'BioMedTec HIC Lübeck'
  }, [])

  return (
    <Wrapper>
      <Header />
      <Helper>
        <FactSection />
        <SectionHeadline
          sectionheadline="A selection of our Research Projects @ HIC Lübeck"
          sectionsubheadline=""
        />
        <Projects />
        <SectionHeadline
          sectionheadline="Start-up Playground @ HIC Lübeck"
          sectionsubheadline=""
        />
        <HealthtecStartUps />
        <SectionHeadline
          sectionheadline="COMPETENCE CENTER @ HIC LÜBECK"
          sectionsubheadline=""
        />
        <Centers />
        <SectionHeadline
          sectionheadline="LAB STRUCTURES AND SERVICES @ HIC Lübeck"
          sectionsubheadline=""
        />
        <LabStructures />
        <StartYourResearch />
        <ContactUs />
      </Helper>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Helper = styled.div``
