import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

export default function Video({ videotarget }) {
  return (
    <Wrapper>
      <RowDiv sd={5} ed={7} sm={2} em={6} ss={2} se={2}>
        <VideoStyled
          width="100%"
          height="100%"
          type="text/html"
          frameBorder="none"
          src={videotarget}
        />
      </RowDiv>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 6.5rem 0 0 0;
`

const RowDiv = styled(Row)`
  padding-bottom: 56.25%;
  position: relative;
`

const VideoStyled = styled.iframe`
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
