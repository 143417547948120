import styled from 'styled-components/macro'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(12, 1fr) 1fr;
  grid-template-rows: max-content;
  gap: ${props =>
    props.gapDefault ? props.gapDefault : '2rem 2rem'};
  margin: ${props => (props.marginDefault ? props.marginDefault : 0)};

  @media ${props => props.theme.breakpoints.m} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: ${props =>
      props.gapMedium ? props.gapMedium : '1rem 1rem'};
    margin: ${props => (props.marginMedium ? props.marginMedium : 0)};
  }

  @media ${props => props.theme.breakpoints.s} {
    grid-template-columns: 0.5rem repeat(4, 1fr) 0.5rem;
    gap: ${props => (props.gapSmall ? props.small : '1rem 1rem')};
    margin: ${props => (props.marginSmall ? props.marginSmall : 0)};
  }
`

export default Grid
