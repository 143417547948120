import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Video from '../components/Video'
import Logo from '../elements/Logo'

export default function Cocoai() {
  useEffect(() => {
    document.title = 'COCOAI'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="COCOAI"
        link1name="Website"
        link1target="https://cocoai.uni-luebeck.de/en"
        link2name="LinkedIn"
        link2target="https://www.linkedin.com/company/cocoai"
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>
        COCOAI Cooperative and communicating AI methods for medical
        image-guided diagnostics
      </H2>
      <H3>CoCoAI – A research project at the University of Lübeck</H3>
      <Video videotarget="https://www.youtube.com/embed/6UcuuKc1VMQ" />
      <PSmall>
        The next step in the development of imaging diagnostic systems
        in medicine is the integration of artificial intelligence (AI)
        so that emergency diagnoses can also be made outside of large
        specialist centers, thus saving lives. But how can holistic,
        responsible development of these be ensured?
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/cocoai/infographic.png" />
        This is the focus of the CoCoAI project, which forms the
        interface between users and developers. Together with medical
        experts and our partner ThinkSono, our multidisciplinary team
        is investigating how an interaction between humans and
        intelligent machines can be beneficial for all parties
        involved. In doing so, we rely on a human-centered research
        and design process.
      </PSmall>
      <PSmall>
        The focus here is on the development of a wearable ultrasound
        device that can detect deep vein thrombosis outside of
        specialist practices and thus contribute to combating one of
        the most common preventable causes of death in Germany. Our
        research thrives on transparency and participation. It exists
        for medical professionals, for potential patients, for
        technology enthusiasts, for everyone.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          Prof. Thomas Franke
          <br />
          Project Manager
        </Li>
        <Li>
          Dr. Christian Herzog
          <br />
          Project Manager
        </Li>
        <Li>
          <a href="mailto:cocoai@imis.uni-luebeck.de">
            cocoai@imis.uni-luebeck.de
          </a>
          <br />
          <a href="mailto:office@imis.uni-luebeck.de">
            office@imis.uni-luebeck.de
          </a>
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://cocoai.uni-luebeck.de/en">
            https://cocoai.uni-luebeck.de/en
          </a>
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/cocoai">
            https://www.linkedin.com/company/cocoai
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
