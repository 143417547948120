import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import CardElement from '../elements/CardElement'

export default function Projects() {
  return (
    <Wrapper>
      <BoxWrapper sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="PASBADIA Research Project"
          text="Patient-centric smartphone-based diagnostics with local and central AI platform for primary care in rural areas ..."
          linktarget="/Pasbadia"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaymedium
          headline="Biomedical optics research projects"
          text="KI-BioSense, Neuro-OCT, Computer Assisted Holographic OCT (HoloOCT), Artificial Intelligence Space for Intelligent Health Systems ..."
          linktarget="/BiomedicalOptics"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="KI-SIGS joint Project"
          text="KI-SIGS – artifical intelligence space for intelligent health systems"
          linktarget="/KISigs"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hiclightblue
          headline="AI Projects @Fraunhofer IMTE"
          text="Fraunhofer Research Institution for Individualized and Cell-Based Medical Engineering ..."
          linktarget="/AIProjects"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="COCOAI Cooperative and communi-cating AI methods for medical image-guided diagnostics"
          text="The next step in the development of imaging diagnostic systems in medicine is the integration of artificial intelligence (AI) so that ..."
          linktarget="/Cocoai"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaymedium
          headline="MIRANA"
          text="MIRANA aims to provide a better experience for hospital visitors"
          linktarget="/Mirana"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="ScreenFM"
          text="Sensor-based Assessment of Infants' Neurological Development Based on Fidgety Movements"
          linktarget="/ScreenFM"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hiclightblue
          headline="SAS-BSOFT Workflow"
          text="Curation and Development of Databases for Small Angle Data in Structural Biology and Soft Matter "
          linktarget="/Sas-bsoft"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="Tape4SXX"
          text=""
          linktarget="/Tape4SXX"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="Amine Sensor"
          text="A novel amine sensor for Histamine intolerance"
          linktarget="/AmineSensor"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hiclightblue
          headline="Auditory Acoustics"
          text="STMD-Evaluation"
          linktarget="/AuditoryAcoustics"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="Artificial Intelligence in Biomedical Signal Processing"
          text=""
          linktarget="/AIBSP"
          linktext="Learn more"
        />
      </BoxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: transparent;
`

const BoxWrapper = styled(Row)`
  background: transparent;
`
