import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function ScreenFM() {
  useEffect(() => {
    document.title = 'ScreenFM'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="ScreenFM"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>ScreenFM</H2>
      <H3>
        Sensor-based Assessment of Infants' Neurological Development
        Based on Fidgety Movements
      </H3>
      <PSmall>(BMBF grant number: 13GW0444E)</PSmall>
      <PSmall>
        Fidgety movements are spontaneous movement patterns in infants
        from 3 to 5 months of age. Abnormal, absent, or sporadic
        movements are associated with a high risk of later
        neurological dysfunction (especially cerebral palsy). The
        detection and classification of such movements in infants is
        therefore paramount to initiate a suitable intervention at an
        early stage if necessary. Such a process is currently
        performed by having medical experts manually annotate video
        recordings of the infants. But because of its high cost - both
        in time and resources -, this procedure can only be offered to
        a very small proportion of infants.
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/screenfm/screenfm1.png" />
        The ScreenFM project aims to increase the availability of this
        procedure by developing an automated system for the detection
        of fidgety movements used to support medical experts (UzL-SPZ)
        in their decision process. To reach this objective, a sensor
        setup is firstly to be proposed to record movement data
        acquired by Inertial Measurement Units placed into the
        infants’ clothing and video data acquired by RGB and depth
        cameras (KAASA, YOUSE, MOTO, UzL-SPZ, UzL-IMI). After the
        acquisition (UzL-SPZ, YOUSE) and annotation (UzL-SPZ) of data
        from a large number of infants, machine learning models are
        then to be trained and evaluated using such a labelled dataset
        (MOTO, UzL-IMI). An app allowing the medical experts to
        consult the model predictions on site is finally to be
        developed (KAASA, MOTO, YOUSE) to perform a step towards the
        development of a marketable product.
      </PSmall>
      <H3>Partners</H3>
      <Ul>
        <Li>Motognosis GmbH (MOTO)</Li>
        <Li>KAASA Solution GmbH (KAASA)</Li>
        <Li>YOUSE GmbH (YOUSE)</Li>
        <Li>
          University of Lübeck – Social Pediatric Center (UzL-SPZ)
        </Li>
        <Li>
          University of Lübeck – Institute of Medical Informatics
          (UzL-IMI)
        </Li>
      </Ul>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/screenfm/screenfm2.png"
          />
          Sebastian Mansow-Model
          <br />
          Project Coordinator, Motognosis GmbH
          <br />
          <a href="mailto:smm@motognosis.com">smm@motognosis.com</a>
        </Li>
        <Li>
          <Img
            portrait
            target="./assets/images/screenfm/screenfm3.jpg"
          />
          Prof. Marcin Grzegorzek
          <br />
          University of Lübeck
          <br />
          <a href="mailto:marcin.grzegorzek@uni-luebeck.de">
            marcin.grzegorzek@uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
