import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function BluuBioSciences() {
  useEffect(() => {
    document.title = 'Bluu BioScience'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Bluu BioSciences"
        link1name="Website"
        link1target="https://bluu.bio"
        link2name="Inteview @ HIC Lübeck (YouTube)"
        link2target="https://youtu.be/9UgRG8jP-Lc"
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>Bluu BioSciences</H2>
      <H3>The first cultivated seafood company in Europe</H3>
      <PSmall>
        <Img
          right
          target="./assets/images/bluubiosciences/srakers.png"
        />
        Bluu Biosciences is the first company in Europe to specialize
        in the development and commercial production of cultivated
        fish. As a pioneer at the interface of biotechnology and food
        technology, Bluu Biosciences is stepping up to produce
        high‐quality, sustainable fish products from cell cultures.
        These feature high nutritional value and a premium flavour
        profile. The company’s aim is to produce fish sustainably and
        cost‐efficiently, thus making a significant contribution to
        securing the future supply of animal protein for mankind. The
        biotech company is based in Berlin and was founded in 2020 by
        Dr Sebastian Rakers and Simon Fabich, supported by an expert
        team of marine biologists, cell biologists, cell and tissue
        engineers as well as food technologists. In R&D, Bluu
        Biosciences closely cooperates with the Fraunhofer Development
        Centre for Marine and Cellular Biotechnology (EMB) in Lübeck.
        The creation of Bluu Biosciences was supported by Berlin‐based
        company builder EVIG, that specializes in promoting
        biotechnology start‐ups in the food sector (keyword: cellular
        agriculture) in cooperation with scientists. In addition to
        the co‐founders and COO, the team consists of experts in the
        fields of marine biology, cell biology, cell and tissue
        engineering as well as food technology. Product Bluu
        Biosciences has set out to produce tasty and healthy fish
        based on fish cell cultures. Research and development are
        focused on the most popular and genetically best‐defined
        edible fish species: atlantic salmon, rainbow trout and carp.
        The company plans to produce hybrid products from cultivated
        fish and plant‐based proteins like fish balls with a first
        product ready by the end of 2022, as well as purely cultivated
        structured products like fish fillets. The products will be
        characterized by an increased omega‐3 fatty acid content based
        on a patented enrichment process and a complete nutritional
        profile. Production In the production of cultivated fish, Bluu
        Biosciences first performs a one‐time, painless biopsy on the
        living animal to obtain adult stem cells. The R&D department
        defines the optimal, serum‐free conditions for unlimited cell
        growth. In the bioreactor, the cell lines are then supplied
        with nutrients and can grow in their medium. Suitable surface
        structures enable their growth in 3D. The nutritional value of
        the cell biomass is optimized and, in its structured form,
        ultimately delivers fish fillets that no longer differ from
        conventional fish in terms of structure, taste and cooking
        behaviour.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          Dr. Sebastian Rakers
          <br />
          managing director
          <br />
          <br />
          Bluu GmbH
          <br />
          MFC I - R&D Department
          <br />
          Maria‐Goeppert‐Straße 1<br />
          23562 Lübeck
          <br />
          <a href="mailto:sebastian@bluu.bio">sebastian@bluu.bio</a>
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bluu.bio">
            www.bluu.bio
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
