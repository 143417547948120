import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import CardElement from '../elements/CardElement'

export default function HealthtecStartUps() {
  return (
    <Wrapper>
      <BoxWrapper sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="mobOx"
          text="mobOx is a mobile blood analyzer for emergency medical servic"
          linktarget="/Mobox"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaymedium
          headline="Bluu BioSciences"
          text=""
          linktarget="/BluuBioSciences"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="Perfood"
          text=""
          linktarget="/Perfood"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hicdaymedium
          headline="FRUBEEN"
          text="effective treatment of malnutrition"
          linktarget="/Frubeen"
          linktext="Learn more"
        />
      </BoxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: transparent;
`

const BoxWrapper = styled(Row)`
  background: transparent;
`
