import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { Color, Paragraph } from '../styles/Variables'

export default function PMid({ children }) {
  return (
    <Wrapper as="div">
      <PMedium as="p" sd={5} ed={7} sm={2} em={6} ss={2} se={2}>
        {children}
      </PMedium>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 4rem 0;
`

const PMedium = styled(Row)`
  ${Paragraph.Medium};
  margin: 0;
  padding: 0;
  color: ${Color.hicdaydarker};
  text-align: left;
`
