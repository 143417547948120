import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Tandem() {
  useEffect(() => {
    document.title = 'TANDEM'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="TANDEM Cross-Innovation-Center"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>TANDEM</H2>
      <PSmall>
        TANDEM CIC, the combined expertise for medical technology in
        northern Germany, is a joint venture of the medical
        departments of the University of Lübeck, the Technische
        Hochschule Lübeck and the University Medical Center
        Schleswig-Holstein.TANDEM unites fundamental research with
        application orientation and clinical know-how through broad
        expertise, many years of experience and close proximity. Our
        team works closely together in a friendly creative atmosphere.
        TANDEM aims to make the knowledge resulting from research,
        development and clinical studies available to companies and
        cooperate with them to innovate new medical applications
        <Img fullwidth target="./assets/images/tandem/tandem1.jpg" />
        From left to right: Prof. Dr. Philipp Rostalski (Project 3),
        Prof. Dr. Stephan Klein (Project 4), Dr. Ralf Brinkmann
        (Project 5), Prof. Dr. Thorsten Buzug (Project 1), Prof. Dr.
        Hartmut Gehring (Project 2) and Holger Fischer
      </PSmall>
      <PSmall>
        The project Cross-Innovation-Center (CIC) – Tandem Phase III
        pursues the goal of substantially broadening the utilization
        basis of research and development work at the University of
        Lübeck and the Lübeck University of Applied Sciences through
        cross-innovation strategies.
      </PSmall>
      <PSmall>
        TANDEM is managed by Holger Fischer, Head of Technology
        Transfer at the University of Lübeck, together with the
        project manager of TANDEM III Kerstin Rönick.
      </PSmall>
      <PSmall>
        The focus is on the exchange between the five following
        projects and their business partners. Furthermore, a
        Cross-Innovation-Center with different approaches will be
        established. These are for example Storytelling about the
        potentials in medical engineering and health research from
        Lübeck, building networks for supporting prototype
        manufacturing or using new formats of events to work with
        companies together, trying out cross-innovation and creating
        new research projects. The exchange with the Start-Up
        environment of the Hanse Innovation Campus Lübeck its driven
        by questions in the medical engineering field and active
        networking with the research groups within the
        Cross-Innovation-Center.
      </PSmall>
      <PSmall>
        We are looking forward to starting cross-innovation with you!
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/tandem/tandem13.jpg"
          />
          Kerstin Rönick
          <br />
          project and network manager
          <br />
          University of Lübeck
          <br />
          <a href="mailto:kerstin.roenick@uni-luebeck.de">
            kerstin.roenick@uni-luebeck.de
          </a>
          <br />
        </Li>
      </Ul>
      <PSmall>
        Five very interesting different medical technology projects
        are currently active in TANDEM Cross-Innovation-Center.
      </PSmall>
      <H3>Project 1 | CT Image Reconstruction with Deep Learning</H3>
      <PSmall>
        <Img right target="./assets/images/tandem/tandem3.png" />
        In this project iterative algorithms for artifact-free images
        within the computed tomography are developed at the Institute
        of Medical Engineering (IMT) together with the Fraunhofer IMTE
        (Institution for Individualized and Cell-Based Medical
        Engineering). The quality and diagnostic value of the images
        should be increased specifically from Patient with metal
        implants.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/tandem/tandem4.jpeg"
          />
          Nele Blum, M.Sc.
          <br />
          Research assistant at the Institute of Medical Engineering
          <br />
          University of Lübeck
          <br />
          <a href="mailto:blum@imt.uni-luebeck.de">
            blum@imt.uni-luebeck.de
          </a>
          <br />
        </Li>
      </Ul>
      <H3>
        Project 2 | Optical and optoacoustic sensors for measuring the
        hemoglobin concentration
      </H3>
      <PSmall>
        Optical and optoacoustic sensors for measuring the hemoglobin
        concentration
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/tandem/tandem14.jpg" />
        Hemoglobin is a fascinating molecule.
        <br />
        The binding, transport and release of oxygen from the lungs to
        the cell is ingeniously solved. Both molecule and function –
        are essential for human life and performance. This is what
        makes the amount of hemoglobin in the human body so valuable.
        And it is the reserve in case of an emergency, either in the
        event of a lack of oxygen or loss of hemoglobin.
        <br />
        These are the reasons for continuously monitoring the
        concentration of hemoglobin without harming a critically ill
        patient.
        <br />
        This is where the project starts. The aim is a sensor and a
        monitoring method for the non-invasive and continuous
        measurement of the hemoglobin concentration in critically ill
        people – for children and adults.
      </PSmall>
      <PSmall>
        Mayda S, Kandemir Z, Bulut N, Maekawa S. Magnetic mechanism
        for the biological functioning of hemoglobin. Nature Scietific
        Reports 2020
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/tandem/tandem5.png" />
          Prof. Dr. med. Hartmut Gehring
          <br />
          Clinic for Anesthesiology and Intensive Care Medicine
          <br />
          Senior Physician, Head of Diagnostics and Intervention
          Division
          <br />
          <a href="mailto:Hartmut.Gehring@uksh.de">
            Hartmut.Gehring@uksh.de
          </a>
          <br />
          University Medical Center Schleswig-Holstein
          <br />
          Campus Lübeck
          <br />
          Ratzeburger Allee 160
          <br />
          23538 Lübeck
        </Li>
      </Ul>
      <H3>
        Project 3 | Model-based development, verification and approval
        of medical assistance systems
      </H3>
      <PSmall>
        <Img right target="./assets/images/tandem/tandem6.jpg" />
        In this project, a concept for testing automated functions of
        medical devices is developed and implemented exemplarily for
        autonomous functions in mechanical ventilation. For this
        purpose, an ontology-based test approach is chosen and the
        tests are used for verification on system level. Thereby, the
        concept for the test system consists of two main components.
        First, a software for defining relevant test scenarios and
        second, a test bench for applying the relevant test scenarios.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/tandem/tandem7.jpg" />
          Sandra Henn, M. Sc.
          <br />
          Research Assistant
          <br />
          <a href="mailto:s.henn@uni-luebeck.de">
            s.henn@uni-luebeck.de
          </a>
          <br />
          +49 451 3101 6225
          <br />
          University of Lübeck
          <br />
          Moislinger Allee 53-55, Building 19
          <br />
          23558 Lübeck
        </Li>
        <Li>
          <Img portrait target="./assets/images/tandem/tandem8.jpg" />
          Prof. Dr. Philipp Rostalski
          <br />
          Director of the Institute for Electrical Engineering in
          Medicine
          <br />
          <a href="mailto:philipp.rostalski@uni-luebeck.de">
            philipp.rostalski@uni-luebeck.de
          </a>
          <br />
          +49 451 3101 6200
          <br />
          University of Lübeck
          <br />
          Moislinger Allee 53-55, Building 19
          <br />
          23558 Lübeck
        </Li>
      </Ul>
      <H3>
        Project 4 | Development and Validation of an In-Vitro-Model to
        Simulate the Transurethral Resection of Prostate Tissue
      </H3>
      <PSmall>
        The transurethrale resection of the prostate (TUR-P) is
        considered the “gold standard” treatment of benign prostatic
        hyperplasia (BPH) that can no longer be controlled by drug
        therapy.
      </PSmall>
      <PSmall>
        <Img left target="./assets/images/tandem/tandem9.jpg" />
        It comprises the iterative ablation of prostate tissue with an
        electrode at the tip of a resectoscope that is inserted
        through the patient’s penis. This process may entail a variety
        of complications, ranging from the perforation of the bladder
        to hypothermia. This project aims to provide an in-vitro-model
        that serves as a development environment for surgical
        instrument manufacturers and doctors and helps to comprehend
        the parameters that may affect said complications. The model
        will be used to foster progress in the improvement of the
        surgery. However, the proposed model will only serve its
        purpose upon completion of a successful validation. Moreover,
        a validated in-vitro-model has the potential to replace animal
        testing. Hence, a suitable validation method will be developed
        in course of this project.
      </PSmall>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/tandem/tandem10.jpg"
          />
          Max Ramien, M.Sc.
          <br />
          Research Assistant
          <br />
          <a href="mailto:max.ramien@th-luebeck.de">
            max.ramien@th-luebeck.de
          </a>
          <br />
          +49 451 300 5518
          <br />
          Technische Hochschule Lübeck
          <br />
          University of Applied Sciences
          <br />
          Laboratory for Medical Sensor and Device Technology
          <br />
          Mönkhofer Weg 239, Building 64
          <br />
          23562 Lübeck
        </Li>
      </Ul>
      <H3>
        Project 5 | Optoacoustic measurement system for breath
        diagnostics
      </H3>
      <PSmall>
        <Img right target="./assets/images/tandem/tandem11.jpg" />
        Concentration changes of different volatile organic compounds
        (VOCs) in breath indicate various diseases, such as infections
        or inflammations of the lungs. Photoacoustic spectroscopy can
        be used to detect specific VOCs – a sound wave is generated by
        modulated/pulsed optical excitation of the substances and heat
        generation by collisional deactivation. The focus of the work
        is the improvement of a photoacoustic measurement cell based
        on finite element simulations of the signal generation as well
        as the experimental realization and optimization of the
        method.{' '}
      </PSmall>
      <PSmall>
        Furthermore, for the extension of the coverable wavelength
        range, a new excitation source (optical parametric oscillator)
        will be realized to improve the measurement method with
        respect to number and selectivity of detectable volatile
        organic compounds.
      </PSmall>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/tandem/tandem12.jpg"
          />
          Dr. Ralf Brinkmann
          <br />
          Institute of Biomedical Optics
          <br />
          <a href="mailto:ralf.brinkmann@uni-luebeck.de">
            ralf.brinkmann@uni-luebeck.de
          </a>
          <br />
          +49 451 3101 3207
          <br />
          University of Lübeck
          <br />
          Peter-Monnik-Weg 4
          <br />
          23562 Lübeck
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
