import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Perfood() {
  useEffect(() => {
    document.title = 'Perfood'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Perfood"
        link1name="Website"
        link1target="https://perfood.de"
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>Perfood</H2>
      <PSmall>
        Perfood develops digital therapeutics powered by personalized
        low-glycemic diet. The company’s DTx for migraine achieves
        effectiveness at the level of modern drugs. Perfood is
        building a pipeline of digital therapeutics with ongoing
        studies and product candidates for migraine, type 2 diabetes,
        PCOS, psoriasis, major depressive disorder and colon cancer.
        Perfood is funded by leading VCs and collaborates with leading
        research institutions and universities.
        <Img
          fullwidth
          target="./assets/images/perfood/perfood1.png"
        />
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/perfood/perfood2.png"
          />
          Dominik Burziwoda
          <br />
          Founder, CEO
          <br />
          E-Mail:{' '}
          <a href="mailto:dominik.burziwoda@perfood.de">
            dominik.burziwoda@perfood.de
          </a>
          <br />
          Telefon:{' '}
          <a href="+49 172 / 37 69 629">+49 172 / 37 69 629</a>
          <br />
          Perfood GmbH
          <br />
          Am Spargelhof 2<br />
          23554 Lübeck
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
