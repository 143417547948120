import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import PSmall from '../components/PSmall'
import PMid from '../components/PMid'
import Video from '../components/Video'
import Logo from '../elements/Logo'

export default function AIProjects() {
  useEffect(() => {
    document.title = 'AI Projects @ Fraunhofer IMTE'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="AI Projects @ Fraunhofer IMTE"
        link1name="Website"
        link1target="https://www.imte.fraunhofer.de/"
        link2name=""
        link2target=""
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>AI Projects @ Fraunhofer IMTE</H2>
      <PMid>
        Fraunhofer Research Institution for Individualized and
        Cell-Based Medical Engineering
      </PMid>
      <H3>KI-SIGS</H3>
      <PSmall>
        The Institute for Electrical Engineering in Medicine is
        involved in several projects within Lübecks flagship
        AI-project KI-SIGS. The application-oriented research project
        KI-SIGS VIKOOB is focusing on the incorporation of visual
        context information for optimizing ventilation therapy. Within
        the activities for the AI-platform development in KI-SIGS, the
        Institute for Electrical Engineering is setting up the
        Responsible AI Research platform and is a key contributor for
        the AI regulatory platform.
      </PSmall>
      <Video videotarget="./assets/IMTE-Teaser.mp4" />
      <PSmall>
        <Img
          portrait
          target="./assets/images/aiprojects/Philipp-Rostalski.jpg"
        />
        For further information, please contact:
        <br />
        Prof. Dr. Philipp Rostalski
        <br />
        <a href="mailto:philipp.rostalski@uni-luebeck.de">
          philipp.rostalski@uni-luebeck.de
        </a>
      </PSmall>
      <H3>AI-based diagnostics</H3>
      <PSmall>
        Surface Electromyography and electrical impedance tomography
        are examples of novel measurement modalities for the diagnosis
        of lung and ventilation parameters. Like most measurement
        modalities applied to the chest, the signals are affected by
        both ventilation and cardiac signal components.
        <Img
          fullwidth
          target="./assets/images/aiprojects/Aufnahmen.png"
        />
      </PSmall>
      <PSmall>
        Due to the overlap in the frequency spectrum of both signals,
        the separation problem for these two components is far from
        trivial. At the Fraunhofer Research Institution for
        Individualized and Cell-based Medical Engineering, powerful
        and probabilistic AI methods such as Gaussian processes and
        probabilistical graphical models are developed, which allow
        the incorporation of uncertainties and prior medical knowledge
        into novel explainable and transparent AI algorithms for large
        time series data.
      </PSmall>
      <PSmall>
        Contact
        <br />
        Jan Graßhoff
        <br />
        <a href="maito:jan.grasshoff@imte.fraunhofer.de">
          jan.grasshoff@imte.fraunhofer.de
        </a>
      </PSmall>
      <H3>Autonomous drones</H3>
      <PSmall>
        <Img
          left
          target="./assets/images/aiprojects/Autonomous-Drones.jpg"
        />
        The Autonomous Systems Lab. at the Institute for Electrical
        Engineering in Medicine focuses on the development of
        data-driven and model-based autonomous systems in safety
        critical domains. The two primary application domains are
        autonomous drones and autonomous medical ventilation. In
        several drone projects ranging from autonomous mapping of a
        port, the inspection of wind turbines as well as the mapping
        and optimization of 5G network access, the IME is developing
        AI-based algorithms for the safe and effective operation of
        tailored and off-the-shelf drones in civil applications. This
        includes the development of tailored quadrocopter platforms,
        the integration of novel sensing and EdgeAI capabilities as
        well as the research on data-driven and model-based algorithms
        for the control of these systems.
      </PSmall>
      <PSmall>
        For further information, please contact:
        <br />
        Prof. Dr. Georg Schildbach
        <br />
        <a href="mailto:georg.schildbach@uni-luebeck.de">
          georg.schildbach@uni-luebeck.de
        </a>
      </PSmall>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
