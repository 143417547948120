import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import { Color, Headline } from '../styles/Variables'
import {
  ArtificialBrain64,
  PhoneIcon,
  MailIcon
} from '../assets/Icons'

export default function ContactUs() {
  return (
    <Wrapper>
      <HeadlineBlock sd={2} ed={5} sm={2} em={6} ss={2} se={2}>
        <BigIcon>{ArtificialBrain64}</BigIcon>
        <H2>Contact Us</H2>
        <H4>
          We will find the perfect match for you at the Hanse
          Innovation Campus Lübeck and connect you to realize suitable
          cooperations. We are at your service:
        </H4>
      </HeadlineBlock>
      <ContentBox as="div" sd={7} ed={3} sm={2} em={3} ss={2} se={2}>
        <Helper>
          <Image
            src={'./assets/hic_ansprechpartner_750x500_jrieckert.jpg'}
          />
          <Name>Janin Rieckert</Name>
          <Title>Network Manager Health Technology</Title>
          <PhoneMail>
            <Icon>{PhoneIcon}</Icon>
            <a href="+49 451 3101 1171">+49 451 3101 1171</a>
          </PhoneMail>
          <PhoneMail>
            <Icon>{MailIcon}</Icon>
            <a href="mailto:rieckert@bio-med-tec.de">
              rieckert@bio-med-tec.de
            </a>
          </PhoneMail>
        </Helper>
      </ContentBox>
      <ContentBox as="div" sd={10} ed={3} sm={5} em={3} ss={2} se={2}>
        <Helper>
          <Image
            src={'./assets/hic_ansprechpartner_750x500_kroenick.jpg'}
          />
          <Name>Kerstin Rönick</Name>
          <Title>
            Project Manager TANDEM III – Cross Innovation Center
          </Title>
          <PhoneMail>
            <Icon>{PhoneIcon}</Icon>
            <a href="tel:+49 451 3101 1155">+49 451 3101 1155</a>
          </PhoneMail>
          <PhoneMail>
            <Icon>{MailIcon}</Icon>
            <a href="mailto:kerstin.roenick@uni-luebeck.de">
              kerstin.roenick@uni-luebeck.de
            </a>
          </PhoneMail>
        </Helper>
      </ContentBox>
      <ContentBox as="div" sd={7} ed={3} sm={2} em={3} ss={2} se={2}>
        <Helper>
          <Image src={'./assets/images/hic/portrait3.png'} />
          <Name>Dr. rer. nat. Britta Beyerlein</Name>
          <Title>Transfer manager</Title>
          <PhoneMail>
            <Icon>{PhoneIcon}</Icon>
            <a href="+49 451 300 5698">+49 451 300 5698</a>
          </PhoneMail>
          <PhoneMail>
            <Icon>{MailIcon}</Icon>
            <a href="mailto:ft@th-luebeck.de">ft@th-luebeck.de</a>
          </PhoneMail>
        </Helper>
      </ContentBox>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin-top: 8rem;
`

const HeadlineBlock = styled(Row)``

const BigIcon = styled.div`
  margin: 0 0 1rem 0;
`

const ContentBox = styled(Row)`
  margin-top: 5rem;
`

const Helper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const H2 = styled.h2`
  ${Headline.H2}
  color: ${Color.hicnight};
  text-transform: uppercase;
`

const H4 = styled.h4`
  ${Headline.H4}
  color: ${Color.hicday};
  margin: 1rem 0 2rem 0;
`

const Image = styled.img`
  width: 100%;
  background: grey;
  margin-bottom: PhoneMail;
`

const Name = styled.p`
  font-size: 1.75rem;
  font-weight: 900;
  color: ${Color.hicnight};
  margin: 0 0 0.5rem 0;
  padding: 0;
`

const Title = styled.p`
  color: ${Color.hicday};
  font-weight: 400;
  margin: 0 0 1rem 0;
  padding: 0;
`

const PhoneMail = styled.span`
  font-weight: 700;
  color: ${Color.hicnight};
  text-decoration: underline;
`

const Icon = styled.span`
  margin-top: 0.3rem;
  margin-right: 0.5rem;
`
