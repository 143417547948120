import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Gateway49() {
  useEffect(() => {
    document.title = 'Gateway 49'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Gateway 49"
        link1target="https://www.gateway49.com/"
        link1name="Homepage"
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>Gateway 49</H2>
      <PSmall>
        <Img right target="./assets/images/gateway49/team.jpg" />
        GATEWAY49 is a startup accelerator from Lübeck, which aims to
        promote startups and innovations and to sustainably support
        the startup scene. Up to 12 teams per year are brought as
        close as possible to market readyness through a structured
        nine-month coaching, mentoring and training program - without
        <Img right target="./assets/images/gateway49/hoodie.jpg" />
        giving up shares and with funding of up to 30,000 euros per
        team per year. The focus of GATEWAY49 is on the targeted
        support of founders in an early idea phase who are pursuing
        (digital) business models, particularly in the focus sectors
        of medical technology, logistics, smart city or food industry.
        Through close cooperation with renowned companies in the focus
        industries, the experts at Lübeck's universities and the
        transfer institutions at the location, the establishment of
        new innovations and the link between startups, business and
        institutions is promoted. The accelerator was initiated by
        TECHNIKZENTRUM Lübeck (TZL) together with the Lübeck Chamber
        of Industry and Commerce and Glocal Consult. The project is
        managed by the TZL and is supported by state and ERDF funds as
        well as partners and sponsors from the regional economy.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          Nils Eckard
          <br />
          Community Manager
          <br />
          Tel. <a href="tel:+49 451 3909 974">+49 451 3909 974</a>
          <br />
          <a href="mailto:nils.eckardt@gateway49.com">
            nils.eckardt@gateway49.com
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
