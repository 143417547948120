import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Mobox() {
  useEffect(() => {
    document.title = 'mobOx'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="mobOx"
        link1target="https://youtu.be/TqR66xdLxNM"
        link1name="Interview @ HIC Lübeck"
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
        logoPath="./assets/images/mobox/logo.png"
      />
      <H2>mobOx</H2>
      <H3>
        mobOx is a mobile blood analyzer for emergency medical
        services
      </H3>
      <PSmall>
        As a compact and lightweight handheld, mobOx enables the fast
        and reliable determination of important blood parameters
        directly at the scene. It provides the emergency medical
        services with the necessary insights to make life-saving
        decisions for the patient at an early stage. This improves
        several processes: Medications can be administered and
        ventilation parameters adjusted already on site or during the
        transport. Emergency room staff can better prepare for the
        patient based on the information transmitted. If necessary,
        the patient can be taken directly to a specialist clinic
        without the detour via the nearest hospital. Earlier diagnosis
        can also avoid unnecessary or even incorrect therapy
        procedures. In addition to a better therapy outcome, the
        healthcare system is relieved and costs are saved. The
        handheld device is used in combination with test chips and
        requires one drop of blood for the analysis (around 20 µL).
        The core and unique selling point of mobOx is the combination
        of innovative optical measurement technology with novel
        AI-based algorithms. These enable mobile use under highly
        fluctuating environmental conditions, especially wide
        temperature range, and thus the immediate availability of
        important blood parameters on site. mobOx is currently under
        development and is expected to be launched on the market in
        2024.
        <Img fullwidth target="./assets/images/mobox/image.jpg" />
      </PSmall>
      <PSmall>
        Blood gases: pH, BE, pCO2, pO2
        <br />
        CO-oximetry: tHb, O2Hb, HHb, COHb, MetHb, SHb, sO2
        <br />
        <br />
        <strong>Latest news</strong>
        <br />
        mobOx achieved the first place at the Gateway49 Accelerator
        and just won the „Gründerpreis 2021“ of the Sparkasse zu
        Lübeck (Founder Award 2021).
        <br />
        Interview @ HIC Lübeck:
        <a
          rel="noreferrer"
          target="_blank"
          href="https://youtu.be/TqR66xdLxNM">
          https://youtu.be/TqR66xdLxNM
        </a>
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/mobox/portrait.jpg" />
          Benjamin Kern
          <br />
          <a href="mailto:benjamin.kern@mobox.health">
            benjamin.kern@mobox.health
          </a>
          <br />
          <a href="tel:+49 151 598 91 548">+49 151 598 91 548</a>
          <br />
          <a rel="noreferrer" href="www.mobox.health" target="_blank">
            www.mobox.health
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
