import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
    }

    html, body {
        height: 100%;

    }

    a {
      text-decoration: none;
      color: inherit;
    }

`
