import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import {
  ConstructionIcon,
  HospitalIcon,
  ArtificialBrain48
} from '../assets/Icons'
import FactElement from '../elements/FactElement'
import { Color } from '../styles/Variables'

export default function FactSection() {
  return (
    <Wrapper id="facts">
      <FactBox sd={2} ed={3} sm={2} em={3} ss={2} se={2}>
        <FactElement
          facticon={ConstructionIcon}
          factnumber="58"
          factdescription="Institutes"
          factinfo=""
        />
      </FactBox>
      <FactBox sd={5} ed={3} sm={5} em={3} ss={2} se={2}>
        <FactElement
          facticon={HospitalIcon}
          factnumber="25"
          factdescription="Clinics"
          factinfo=""
        />
      </FactBox>
      <FactBox sd={8} ed={3} sm={2} em={3} ss={2} se={2}>
        <FactElement
          facticon={ArtificialBrain48}
          factnumber="> 2.100"
          factdescription="innovation minds"
          factinfo=""
        />
      </FactBox>
      <FactBox sd={11} ed={3} sm={5} em={3} ss={2} se={2}>
        <FactElement
          facticon={ArtificialBrain48}
          factnumber="> 10.000"
          factdescription="students"
          factinfo=""
        />
      </FactBox>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: ${Color.factsbackground};
  padding: 4rem 0;
  @media ${props => props.theme.breakpoints.s} {
    padding: 2rem 0;
  }
`

const FactBox = styled(Row)`
  background: transparent;
  padding: 8.25rem 0;
  @media ${props => props.theme.breakpoints.m} {
    padding: 2rem;
  }
  @media ${props => props.theme.breakpoints.s} {
    padding: 2rem;
  }
`
