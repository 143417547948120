import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import { Color, Paragraph } from '../styles/Variables'
import { LogoLarge } from '../assets/Icons'

export default function Footer() {
  return (
    <Wrapper>
      <HeadlineBlock sd={2} ed={12} sm={2} em={6} ss={2} se={2}>
        Let´s drive change in health technology. Together.
      </HeadlineBlock>
      <HR sd={6} ed={4} sm={3} em={4} ss={2} se={2} />
      <LogoBlock sd={2} ed={2} sm={2} em={2} ss={2} se={2}>
        {LogoLarge}
      </LogoBlock>
      <AddressBlock sd={5} ed={3} sm={5} em={2} ss={2} se={2}>
        <P>
            Hanse Innovation Campus Lübeck
        </P>
          <UL>
              <LI>BioMedTec Management GmbH (As of 1st December 2021: Hanse Innovation Campus GmbH)*</LI>
              <LI>*The shareholders are the University of Lübeck and the Technische Hochschule Lübeck.</LI>
          </UL>
      </AddressBlock>
      <ImprintBlock sd={11} ed={3} sm={5} em={2} ss={2} se={2}>
        <UL>
          <LI>
            <a
              href="https://bio-med-tec.de/impressum/"
              target="_blank"
              rel="noreferrer">
              Impressum
            </a>
          </LI>
          <LI>
            <a
              href="https://bio-med-tec.de/datenschutzerklaerung-nach-der-datenschutz-grundverordnung-dsgvo/"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
          </LI>
        </UL>
          <LWP>
              <img src="./assets/logo_LPW_engl_jpg.png" />
          </LWP>
      </ImprintBlock>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: ${Color.hicnight};
  margin-top: 8rem;
  padding: 2rem 0 6rem 0;
  position: relative;
  @media ${props => props.theme.breakpoints.s} {
        padding-bottom: 9rem;
  }
`

const HeadlineBlock = styled(Row)`
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 900;
  color: ${Color.hicearth};
  text-align: center;
  padding: 2rem 0;
`

const HR = styled(Row)`
  border: none;
  border-top: 2px solid ${Color.hicearth};
`

const LogoBlock = styled(Row)`
    width: 160px;
`

const AddressBlock = styled(Row)`
  margin-top: 6rem;
  color: ${Color.white};
`

const ImprintBlock = styled(Row)`
  margin-top: 6rem;
  color: ${Color.white};
`

const UL = styled.ul`
  list-style: none;
`

const LI = styled.li`
  list-style: none;
`

const P = styled.p`
  ${Paragraph.small}
  font-weight: 800;
  margin-bottom: 1rem;
`

const LWP = styled.div`
    position: absolute;
    bottom: 0;
    padding: 12px;
    background: #fff;
    @media ${props => props.theme.breakpoints.m} {
        left: 25px;
    }
`