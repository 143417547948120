import styled from 'styled-components/macro'
import { Color } from '../styles/Variables'

export default function InstituteBox(props) {
  return (
    <BoxStyled>
      <Headline>
        <a href={props.target} target="_blank" rel="noreferrer">
          {props.headline}
        </a>
      </Headline>
      <Description>{props.description}</Description>
    </BoxStyled>
  )
}

const BoxStyled = styled.div``

const Headline = styled.a`
  overflow-wrap: break-word;
  margin: 0 0 0.75rem 0;
  padding: 0;
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 900;
  text-align: left;
  text-decoration: 2px underline ${Color.hicnight};
  color: ${Color.hicorange};
  transition: 0.1s ease-in-out;
  font-family: 'MenaGrotesk-Black', sans-serif;
  &:hover {
    color: ${Color.hicnight};
  }
`

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: left;
  color: ${Color.hiclightblue};
`
