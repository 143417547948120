import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { Color, Paragraph } from '../styles/Variables'

export default function Ul({ children }) {
  return (
    <Wrapper as="div">
      <UlStyled as="ul" sd={5} ed={7} sm={2} em={6} ss={2} se={2}>
        {children}
      </UlStyled>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 4rem 0;
`

const UlStyled = styled(Row)`
  ${Paragraph.Small};
  margin: 0;
  padding: 0;
  color: ${Color.hicnight};
  text-align: left;
  list-style: none;
`
