import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Hic() {
  useEffect(() => {
    document.title = 'Hanse Innovation Campus Lübeck'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Hanse Innovation Campus Lübeck"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
        logoPath=""
      />
      <H2>Hanse Innovation Campus Lübeck</H2>
      <H3>Who we are</H3>
      <PSmall>
        We are an innovative campus – an interdisciplinary network in
        education, research and transfer. We cooperate closely and our
        campus offers everything that constitutes education, research
        and transfer on the spot.
        <br />
        Our work benefits from the high concentration of scientific
        and technical expertise on campus.
        <br />
        <br />
        Our main research areas are Medical Engineering, Smart City,
        Nutrition, Precision Medicine and Artificial Intelligence.
        <br />
        Connect with us – excellent Science. Together.
        <br />
        <br />
        The Hanse Innovation Campus GmbH is your first contact to find
        the perfect contact person and to realize suitable
        cooperations.
      </PSmall>
      <H3>We are at your service:</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/hic/portrait1.jpg" />
          Anna Lena Paape
          <br />
          <a href="tel:+49 451 3101 1170">+49 451 3101 1170</a>
          <br />
        </Li>
        <Li>
          <Img portrait target="./assets/images/hic/portrait2.jpg" />
          Janin Rieckert
          <br />
          <a href="tel:+49 451 3101 1171">+49 451 3101 1171</a>
          <br />
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
