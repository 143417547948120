import { HICIconDarker } from '../assets/Icons'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export default function Logo() {
  return (
    <Wrapper>
      <Icon sd={2} ed={2} sm={2} em={2} ss={2} se={2}>
        <Link to="/">{HICIconDarker}</Link>
      </Icon>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)``

const Icon = styled(Row)`
  margin-top: 2rem;
`
