import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function BiomedicalOptics() {
  useEffect(() => {
    document.title = 'Biomedical Optics'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Biomedical Optics"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>BIOMEDICAL OPTICS RESEARCH PROJECTS</H2>
      <H3>KI-BioSense</H3>
      <PSmall>
        <Img
          right
          target="./assets/images/biomedicaloptics/ki-bio-science.jpg"
        />
        Funding Agency: German Federal Ministry of Food and
        Agriculture (BMEL) Partners: BMO, ISEF (Leucker), IEM (Sina),
        BAADER GmbH, GALAB GmbH, GEA AG, Porlabs GmbH, TecSense GmbH
        Goal: Quality monitoring along the food process chain using
        biosensors and artificial intelligence. Input AI: Improvement
        of biosensors
        https://ki-biosense.isp.uni-luebeck.de/wordpress/
      </PSmall>
      <H3>Neuro-OCT</H3>
      <PSmall>
        Funding Agency: BMBF – – Federal Ministry of Education and
        Research – BMBF Optical coherence tomography for
        intraoperative detection and visualization of tumor boundaries
        and vascular architecture in neurosurgery.
      </PSmall>
      <PSmall>
        <Img
          right
          target="./assets/images/biomedicaloptics/neuro-oct.jpg"
        />
        AI: Tissue discrimination (healthy/tumor) in the brain using
        3D OCT volume images based on a training dataset of
        histologically correlated pathological clinical tissue
        samples. https://mll-luebeck.com/en/public-projects/neuro-oct/
      </PSmall>
      <PSmall>
        <Img
          left
          target="./assets/images/biomedicaloptics/bmo-team.jpg"
        />
        Partners:
        <br />
        University of Lübeck, Institute for Biomedical Optics
        <br />
        Medical Laser Center Lübeck GmbH
        <br />
        University of Lübeck, UKSH, Clinic for Neurosurgery
      </PSmall>
      <H3>Computer Assisted Holographic OCT (HoloOCT)</H3>
      <PSmall>
        <Img
          right
          target="./assets/images/biomedicaloptics/HoloOCT-Team.jpg"
        />
        Funding Agency: BMBF – Federal Ministry of Education and
        Research – BMBF Partners: BMO, Charite Berlin,Heidelberg
        Engineering, Thorlabs, Nocturne Goal: Functional retinal
        imaging Input AI: Phase correct reconstruction of OCT data.
      </PSmall>
      <H3>
        Artificial Intelligence Space for Intelligent Health Systems
        (Ki-SIGS)
      </H3>
      <PSmall>
        <Img
          right
          target="./assets/images/biomedicaloptics/bmo-scanner.jpg"
        />
        Sponsor: BMBF – – Federal Ministry of Education and Research –
        BMBF Partners: BMO, IMI, Kiel Eye Hospital, Institute for
        Computer Science of the CAU, University of Bremen, Goal:
        HomeCare-OCT
      </PSmall>
      <PSmall>
        For further information, please contact Prof. Dr. Gereon
        Hüttmann Deputy Head, Institute of Biomedical Optics
        University of Lübeck
      </PSmall>
      <H3>Input AI: Analysis of OCT data for AMD diagnostics</H3>
      <PSmall>
        OCT-optimized laser therapy of basal cell carcinoma: Optical
        coherence tomography for AI-based diagnosis and control of
        laser therapy of basal cell carcinoma (OCTOLAB) Sponsor: BMBF
        – – Federal Ministry of Education and Research – BMBF
        Partners: MLL, Hypertech Laser Systems GmbH, Medical
        Informatics Augsburg, Augsburg University Hospital Aim:
        Automated diagnosis and laser therapy of basal cell carcinoma
        Input AI: Image analysis and laser control
      </PSmall>
      <H3>
        Diagnosis of microbial inflammations in the middle ear by
        combination of OCT and laser ablation
      </H3>
      <PSmall>
        Sponsor: BMBF – – Federal Ministry of Education and Research –
        BMBF Partners: MLL, ARC Laser GmbH, Schölly Fibroptic GmbH,
        Curefab, ENT clinic UKSH Lübeck Objective: Diagnostics and
        laser therapy of chronic inner ear infections Input AI:
        Reconstruction of OCT data, registration and image analysis
      </PSmall>
      <H3>
        Mechatronically guided micronavigation of needles in soft
        tissue
      </H3>
      <PSmall>
        Sponsor: DFG Partner: Prof. Alexander Schläfer, TU Harburg
        Aim: Navigation of probes in biological tissue Input AI:
        Analysis of OCT data a PhD student working with us but
        supervised by Prof. Handel on Neuro-OCT.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/biomedicaloptics/Robert_Huber.jpg"
          />
          Prof. Dr. Robert Huber
          <br />
          Director and Chair
          <br />
          Institute for Biomedical Optics
          <br />
          University of Lübeck
          <br />
          Peter-Monnik-Weg 4
          <br />
          23562 Lübeck
          <br />
          Tel.: +49 (451) 3101-3200
          <br />
          Mail:{' '}
          <a href="mailto:robert.huber@uni-luebeck.de">
            robert.huber@uni-luebeck.de
          </a>
          <br />
          Web:{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.bmo.uni-luebeck.de">
            www.bmo.uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
