import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Caraqa() {
  useEffect(() => {
    document.title = 'CARAQA'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Further Education - CARAQA"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>Further Education - CARAQA</H2>
      <PSmall>
        <Img left target="./assets/images/caraqa/caraqa1.png" />
        In cooperation with Swiss MedTech company Medidee and
        Technische Hochschule Lübeck, oncampus offers a unique
        training to face the current major challenges of the new
        medical EU regulations.
      </PSmall>
      <PSmall>
        After completing the CARAQA training you will be able to
        perform a smooth transition to the new requirements under
        MDR/IVDR.
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/caraqa/caraqa2.png" />
        The 360 degree learning experience ensures a balance between
        theoretical aspects and hands-on practice: Experts from the
        university sector and the MedTech company Medidee as well as
        regional and international industry representatives will share
        their insights and experience, will empower you with advanced
        knowledge and provide you with a broad range of skills to take
        duty as a person responsible for regulatory compliance (PRRC)
        within your company.
        <Img fullwidth target="./assets/images/caraqa/caraqa3.png" />
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/caraqa/caraqa4.png" />
          oncampus for CARAQA
          <br />
          Sabrina Reis
          <a href="tel:+49 451 160818 62">+49 451 160818 62</a>
          <br />
          <a href="mailto:caraqa@oncampus.de">caraqa@oncampus.de</a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
