import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'
import Row from '../layout/Row'

export default function KISigs() {
  useEffect(() => {
    document.title = 'KI-SIGS'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="KI-SIGS"
        link1name="Website"
        link1target="https://ki-sigs.de"
        link2name="Youtube"
        link2target="https://www.youtube.com/channel/UClg7rtiOGbrZGDRxNGnSf5A"
        link3name=""
        link3target=""
        link4name=""
        link4target=""
      />
      <H2>KI-SIGS JOINT PROJECT</H2>
      <H3>
        KI-SIGS – ARTIFICIAL INTELLIGENCE SPACE FOR INTELLIGENT HEALTH
        SYSTEMS
      </H3>
      <PSmall>
        <Img fullwidth target="./assets/images/kisigs/ki-sigs1.jpg" />
        The aim of KI-SIGS is to create a common institutional
        framework, the „KI-Space“, for the model region of Northern
        Germany. The establishment is done in collaboration with
        Northern Germany AI institutes in Bremen, Hamburg and
        Schleswig-Holstein together with medical technology companies
        and partners from the university clinics. It serves to develop
        medical AI technologies better and faster and to apply them.
        The central instrument for this is a platform through which
        the transfer of knowledge is organized and planned
        developments are coordinated. In addition, regulatory and
        ethical requirements are jointly developed and the connection
        to other networks related to AI and medicine is made possible.
        Further nine A&I projects provide the necessary input and the
        requirements for setting up the platform.
        <Img fullwidth target="./assets/images/kisigs/ki-sigs2.jpg" />
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/kisigs/leucker.png" />
          Prof. Dr. Martin Leucker
          <br />
          Director
          <br />
          UniTransferKlinik Lübeck GmbH
          <br />
          Multifunktions Center
          <br />
          Maria-Goeppert-Straße 1
          <br />
          23562 Lübeck
          <br />
          Tel.: +49 (0) 451 – 3101 6500
          <br />
          Mail:{' '}
          <a href="mailto:leucker@unitransferklinik.de">
            leucker@unitransferklinik.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled(Row)``
