export const Color = {
  hicday: '#009CAD',
  hicdaymedium: '#00B4C8',
  hicdaydarker: '#00818F',
  hicearth: '#50BEB4',
  hiclightblue: '#1B5E96',
  hicnight: '#14376E',
  hicdarknight: '#09244E',
  hicbutteryellow: '#FFFDDD',
  hicafterwork: '#FEE901',
  hicorange: '#EBC500',
  factsbackground: '#EEFDFF',
  white: '#FFFFFF'
}

export const Headline = {
  H1: `
    font-size: 4rem;
    line-height: 4rem;
    font-family: 'MenaGrotesk-Black',sans-serif;
    font-weight: 900;`,
  H2: `
    font-size: 2.875rem;
    line-height: 2.875rem;
    font-family: 'MenaGrotesk-Black',sans-serif;
    font-weight: 900;
     @media ${props => props.theme.breakpoints.s} {
        font-size: 2rem;
        line-height: 2.2rem;
    }
    @media ${props => props.theme.breakpoints.m} {
      font-size: 2.3rem;
      line-height: 2.4rem;
    }
    `,
  H3: `
    font-size: 2.25rem;
    line-height: 2.625rem;
    font-weight: 900;`,
  H4: `
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 400;`
}

export const Paragraph = {
  Small: `
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 400;`,
  Medium: `
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 400;`,
  Large: `
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;`
}
