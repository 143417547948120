import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function FabLab() {
  useEffect(() => {
    document.title = 'FabLab Lübeck'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="FabLab Lübeck"
        link1name="Website"
        link1target="www.fablab-luebeck.de"
        link2name="Web-Forum"
        link2target="forum.fablab-luebeck.de"
        link3name="Facebook"
        link3target="facebook.com/fablabluebeck"
        link4name="Twitter"
        link4target="twitter.com/FablabLuebeck"
      />
      <H2>FabLab Lübeck</H2>
      <H3>
        Patient-centric smartphone-based diagnostics with local and
        central AI platform for primary care in rural areas
      </H3>
      <PSmall>
        FabLab Lübeck is an open innovation laboratory and workshop
        located in Lübeck on the HIC Lübeck. We offer access to
        hightech machinery to students, startups, makers and
        hobbyists. Our members provide courses and instructions for
        the machines, so you can learn how to operate them
        independently and start your own projects.
      </PSmall>
      <PSmall>
        Besides lasercutter, 3d-printers, soldering-equipment and CNC
        milling machines, we also feature sewing and stitching
        machines, wood and metal working equipment and 3d scanning
        devices. Our community provides support and knowledge on broad
        topics, such as programming, electronics, metal- and
        woodworking and 3D-printing.
      </PSmall>
      <PSmall>
        FabLab Lübeck is part of the worldwide FabLab community and is
        a non-profit association based in Lübeck. If you are
        interested in paying us a visit, we offer tours every Friday
        evening from 6 to 10pm.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img portrait target="./assets/images/fablab/fablab1.png" />
          Lisa Hack &amp; Martin Hamann
          <br />
          Labmanagement des FabLabs
          <br />
          FabLab Lübeck e.V.
          <br />
          Maria-Goeppert-Straße 9a
          <br />
          23562 Lübeck
          <br />
          <a href="mailto:labmanagement@fablab-luebeck.de">
            labmanagement@fablab-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
