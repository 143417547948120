import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Crabs() {
  useEffect(() => {
    document.title = 'CRABS'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="CRABS"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>
        CRABS Centre for Regulatory Affairs in Biomedical Sciences
      </H2>
      <H3>Regulatory Affairs on the HIC Lübeck Campus</H3>
      <PSmall>
        Regulatory Affairs for medical devices is a scientific
        discipline addressing elements related to the marketing
        authorization/conformity assessment of medical devices aiming
        at the development of strategies to comply most efficiently
        with quality, safety and efficacy/performance requirements for
        medical devices within the applicable legal framework.
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/crabs/ra.jpg" />
        The „Centre for Regulatory Affairs in Biomedical Sciences –
        CRABS“ located at the University of Applied Sciences Lübeck
        works on numerous projects related to innovative products and
        technologies including personalized medical devices,
        (AI-based) software and others. Our expertise relies on
        long-term cooperation with the University of Lübeck, the
        Fraunhofer IMTE, the IHK Lübeck and regional industry
        representatives.
      </PSmall>
      <PSmall>
        Regulatory Affairs (RA) in Lübeck is based on four major
        pillars: Service, Research, Standardisation and Education
        (compare Figure 1).
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/crabs/spitzenberger.jpg"
          />
          Prof. Dr. Folker Spitzenberger
          <br />
          <a href="mailto:folker.spitzenberger@th-luebeck.de">
            folker.spitzenberger@th-luebeck.de
          </a>
          <br />
          Technische Hochschule Lübeck
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.th-luebeck.de/hochschule/fachbereich-angewandte-naturwissenschaften/labore/centre-for-regulatory-affairs-in-biomedical-sciences/uebersicht/">
            https://www.th-luebeck.de/hochschule/fachbereich-angewandte-naturwissenschaften/labore/centre-for-regulatory-affairs-in-biomedical-sciences/uebersicht/
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
