import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function OLRIM() {
  useEffect(() => {
    document.title = 'OLRIM'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="OLRIM"
        link1name="Website"
        link1target="https://www.olrim.uni-luebeck.de/en/open-lab.html"
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>OLRIM</H2>
      <H3>
        Open Lab for Robotics and Imaging in Industry and Medicine
      </H3>
      <PSmall>
        The Open Lab for Robotics and Imaging in Industry and Medicine
        (OLRIM) offers access to expert knowledge and hardware
        available at the participating institutes including various
        robots (parallel, serial, mobile, collaborative), medical and
        industrial imaging and camera systems such as ultrasouond
        stations, compact MRIs and CTs, a C-Arm and different
        3D-scanner as well as 3D printer. We are open to long term
        scientific research and development cooperations as well as
        short-term scientific services. We offer scientific and
        technical consulting and we provide soft- and hardware
        solutions as well as access to computing capacities.
      </PSmall>
      <PSmall>
        <Img fullwidth target="./assets/images/olrim/olrim1.jpg" />
        The OLRIM is a joint institution of the Institute for Robotics
        and Cognitive Systems and the Institute of Medical Engineering
        at the University of Lübeck.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/olrim/mariahenke.jpg"
          />
          Dr. Maria Henke
          <br />
          Projectmanager OLRIM
          <br />
          University of Lübeck
          <br />
          Institute for Robotics and Cognitive Systems
          <br />
          +49 451 3101 5226
          <br />
          <a href="mailto:henke@rob.uni-luebeck.de">
            henke@rob.uni-luebeck.de
          </a>
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.olrim.uni-luebeck.de">
            www.olrim.uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
