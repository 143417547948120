import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PMid from '../components/PMid'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function EthicalInnovation() {
  useEffect(() => {
    document.title = 'Ethical Innovation Hub'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Ethical Innovation Hub"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>
        Inter- &amp; Transdisciplinary Research for Ethics as a Driver
        of Innovation
      </H2>
      <H3>Why Ethics of Technology?</H3>
      <PMid>
        "Technologies have a clear moral dimension—that is to say, a
        fundamental aspect that relates to values, ethics, and norms.
        Technologies reflect the interests, behaviors, and desires of
        their creators, and shape how the people using them can
        realize their potential, identities, relationships, and
        goals."
        <br />
        <br />
        Philbeck, T., Davis, N., & Larsen, A. M. E. (2018). World
        Economic Forum – Values, Ethics and Innovation Rethinking
        Technological Development in the Fourth Industrial Revolution.
      </PMid>
      <H3>Ethical Innovation Conceptual Approach</H3>
      <Ul>
        <Li>
          - It is the goal to integrate the consideration of ethical
          and societal aspects directly from the beginning of the
          technology development process.
        </Li>
        <Li>
          - The Ethical Innovation Hub is doing research on
          appropriate methods for supporting development teams in
          ethical reflection.
        </Li>
        <Li>
          - Methodically, we engage in participative research
          frameworks and open-ended discursive deliberation beyond
          “acceptance generating” or “reputation building” endeavors.
        </Li>
      </Ul>
      <PSmall>
        <Img
          fullwidth
          target="./assets/images/ethicalinnovation/ei1.jpg"
        />
        In the project CoCoAI, we are investigating how a conceptual
        approach to cooperative and communicating AI can lead to
        concrete design decisions. The case study that is being
        considered is a deep learning-supported approach to a mobile
        and point-of-care solution to the ultrasound-based diagnostic
        of deep vein thromboses.
      </PSmall>
      <PSmall>
        <Img
          fullwidth
          target="./assets/images/ethicalinnovation/ei3.jpg"
        />
        As part of the large KI-SIGS consortium, the Ethical
        Innovation Hub is responsible for the Responsible Innovation
        Platform in the novel and growing AI health ecosystem. We
        participate in the development of a few application projects
        and provide both methodical and informative material for the
        dissemination to all ecosystem partners.
      </PSmall>
      <PSmall>
        <Img
          fullwidth
          target="./assets/images/ethicalinnovation/ei4.jpg"
        />
        In the DigS-Gov project, we investigate the ethical and social
        implications of approaches to visualize and inform about
        administrative data flows for the purpose of increasing the
        digital sovereignty of citizens.
      </PSmall>
      <PSmall>
        <Img
          fullwidth
          target="./assets/images/ethicalinnovation/ei5.jpg"
        />
        The Virtual Ethical Innovation Lecture (VEIL) is a series of
        lectures on the broad spectrum of the ethics of technology
        both from a theoretical and applied perspective. The lectures
        typically try to be brief and accessible, giving room for
        questions and discourse on the subject matter.
      </PSmall>
      <PSmall>
        <Img
          fullwidth
          target="./assets/images/ethicalinnovation/ei6.jpg"
        />
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          Universität zu Lübeck
          <br />
          Ethical Innovation Hub
          <br />
          <a href="tel:+49 451 3101 6211">+49 451 3101 6211</a>
          <br />
          <a href="mailto:eih.contact@uni-luebeck.de">
            eih.contact@uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
