import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import ScrollToTop from './assets/ScrollToTop'

import StartPage from './pages/StartPage'
import Pasbadia from './pages/Pasbadia'
import BiomedicalOptics from './pages/BiomedicalOptics'
import KISigs from './pages/KISigs'
import AIProjects from './pages/AIProjects'
import Crabs from './pages/Crabs'
import Cocoai from './pages/Cocoai'
import Mirana from './pages/Mirana'
import ScreenFM from './pages/ScreenFM'
import AICenter from './pages/AICenter'
import AILab from './pages/AILab'
import AIBSP from './pages/AIBSP'
import EthicalInnovation from './pages/EthicalInnovation'
import OLRIM from './pages/OLRIM'
import Gateway49 from './pages/Gateway49'
import FabLab from './pages/FabLab'
import Tape4SSX from './pages/Tape4SSX'
import SasbSoft from './pages/SasbSoft'
import Frubeen from './pages/Frubeen'
import Mobox from './pages/Mobox'
import BluuBioSciences from './pages/BluuBioSciences'
import Perfood from './pages/Perfood'
import GruenderCube from './pages/GruenderCube'
import PedMedDev from './pages/PedMedDev'
import Hic from './pages/Hic'
import Caraqa from './pages/Caraqa'
import AmineSensor from './pages/AmineSensor'
import AuditoryAcoustics from './pages/AuditoryAcoustics'
import Tandem from './pages/Tandem'

export default function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/Pasbadia" element={<Pasbadia />} />
          <Route
            path="/BiomedicalOptics"
            element={<BiomedicalOptics />}
          />
          <Route path="/KISigs" element={<KISigs />} />
          <Route path="/AIProjects" element={<AIProjects />} />
          <Route path="/Crabs" element={<Crabs />} />
          <Route path="/Cocoai" element={<Cocoai />} />
          <Route path="/Mirana" element={<Mirana />} />
          <Route path="/ScreenFM" element={<ScreenFM />} />
          <Route path="/Sas-bsoft" element={<SasbSoft />} />
          <Route path="/AICenter" element={<AICenter />} />
          <Route path="/AILab" element={<AILab />} />
          <Route path="/AIBSP" element={<AIBSP />} />
          <Route
            path="/EthicalInnovation"
            element={<EthicalInnovation />}
          />
          <Route path="/Olrim" element={<OLRIM />} />
          <Route path="/Gateway49" element={<Gateway49 />} />
          <Route path="/FabLab" element={<FabLab />} />
          <Route path="/Tape4SSX" element={<Tape4SSX />} />
          <Route path="/Frubeen" element={<Frubeen />} />
          <Route path="/Mobox" element={<Mobox />} />
          <Route
            path="/BluuBioSciences"
            element={<BluuBioSciences />}
          />
          <Route path="/Perfood" element={<Perfood />} />
          <Route path="/GruenderCube" element={<GruenderCube />} />
          <Route path="/PedMedDev" element={<PedMedDev />} />
          <Route path="/Hic" element={<Hic />} />
          <Route path="/Caraqa" element={<Caraqa />} />
          <Route path="/AmineSensor" element={<AmineSensor />} />
          <Route
            path="/AuditoryAcoustics"
            element={<AuditoryAcoustics />}
          />
          <Route path="/Tape4SXX" element={<Tape4SSX />} />
          <Route path="/Tandem" element={<Tandem />} />
        </Routes>
      </Router>
    </>
  )
}
