import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Tape4SSX() {
  useEffect(() => {
    document.title = 'Tape4SSX'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Tape4SSX"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>Tape4SSX</H2>
      <H3>
        An easy and efficient sample environment for serial protein
        crystallography and drug screening
      </H3>
      <PSmall>
        Tape4SSX aims to develop an efficient and user-friendly sample
        environment for serial crystallography. The polymer tapedrive
        system optimizes signal to noise ratio by blotting away mother
        liquor surrounding the µm-sized crystals and enables
        observation of conformational changes of crystallized proteins
        in a time-resolved manner.This setup will be installed at the
        European Synchrotron Radiation Facility (ESRF) in Grenoble.
      </PSmall>
      <PSmall>
        Funding: Bundesministerium für Bildung und Forschung
        <br />
        Project partner: European Synchrotron Radiation Facility
        (ESRF) Grenoble
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/tape4ssx/tape4ssx2.jpg"
          />
          Mia Lahey-Rudolph
          <br />
          Research Assistant
          <br />
          <a href="mailto:mia.lahey-rudolph@th-luebeck.de">
            mia.lahey-rudolph@th-luebeck.de
          </a>
        </Li>
        <Li>
          <Img
            portrait
            target="./assets/images/tape4ssx/tape4ssx3.jpg"
          />
          Prof. Dr. Manfred Rößle
          <br />
          Laboratory for X-ray Engineering
          <br />
          <a href="mailto:manfred.roessle@th-luebeck.de">
            manfred.roessle@th-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
