import styled from 'styled-components/macro'
import { Color, Headline, Paragraph } from '../styles/Variables'
import { LinkArrowYellow } from '../assets/Icons'
import { Link } from 'react-router-dom'

export default function CardElement(props) {
  return (
    <Wrapper {...props} to={props.linktarget}>
      <div>
        <H3>{props.headline}</H3>
        <P>{props.text}</P>
      </div>
      <LinkStyled>
        {props.linktext}
        <Arrow>{LinkArrowYellow}</Arrow>
      </LinkStyled>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  word-break: break-word;
  cursor: pointer;
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  justify-items: center;
  padding: 2rem;
  height: 100%;
  min-height: 512px;
  @media ${props => props.theme.breakpoints.s} {
  }
  @media ${props => props.theme.breakpoints.m} {
    min-height: 250px;
  }
  background-color: ${props =>
    props.hicday
      ? `${Color.hicday}`
      : props.hicdaymedium
      ? `${Color.hicdaymedium}`
      : props.hicdaydarker
      ? `${Color.hicdaydarker}`
      : props.hicearth
      ? `${Color.hicearth}`
      : props.hiclightblue
      ? `${Color.hiclightblue}`
      : props.hicnight
      ? `${Color.hicnight}`
      : props.hicdarknight
      ? `${Color.hicdarknight}`
      : props.hicbutteryellow
      ? `${Color.hicbutteryellow}`
      : props.hicafterwork
      ? `${Color.hicafterwork}`
      : props.hicorange
      ? `${Color.hicorange}`
      : 'hotpink'};
  color: ${props =>
    props.hicorange ? `${Color.hicnight}` : `${Color.white}`};
  text-align: center;
  transition: 0.2s ease-in-out;
  &:hover {
    scale: 1.01;
    box-shadow: 0px 0px 3.3px -7px rgba(150, 150, 150, 0.319),
      0px 0px 11.2px -7px rgba(150, 150, 150, 0.471),
      0px 0px 50px -7px rgba(150, 150, 150, 0.79);
  }
`

const H3 = styled.h3`
  ${Headline.H3};
  padding: 0;
  margin: 0;
`

const P = styled.p`
  ${Paragraph.Small};
  margin: 0;
  padding: 1rem 0 0 0;
`

const LinkStyled = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0 0.5rem 0;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  font-family: 'MenaGrotesk-Black', sans-serif;
  letter-spacing: 0.2em;
  color: ${props =>
    props.contact ? `${Color.hicnight}` : `${Color.white}`};
`

const Arrow = styled.span`
  padding-top: 0.15rem;
`
