import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'

import CardElement from '../elements/CardElement'

export default function Centers() {
  return (
    <Wrapper>
      <BoxWrapper sd={2} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="CRABS Centre for Regulatory Affairs in Biomedical Sciences"
          text="Regulatory Affairs for medical devices is a scientific discipline addressing elements related to the marketing authorization/conformity ..."
          linktarget="/Crabs"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={5} em={3} ss={2} se={4}>
        <CardElement
          hicdaymedium
          headline="AI Center Lübeck"
          text="The Center for Artificial Intelligence Lübeck supports local AI activities in a variety of ways ..."
          linktarget="/AICenter"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicdaydarker
          headline="PedMedDev"
          text="The Hub for Pediatric Medical Devices"
          linktarget="/PedMedDev"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={2} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          hiclightblue
          headline="TANDEM"
          text="Cross-Innovation Center"
          linktarget="/Tandem"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={6} ed={4} sm={2} em={3} ss={2} se={2}>
        <CardElement
          hicnight
          headline="ETHICAL INNOVATION HUB"
          text="Inter- &amp; Transdisciplinary Research for Ethics as a Driver of Innovation ..."
          linktarget="/EthicalInnovation"
          linktext="Learn more"
        />
      </BoxWrapper>
      <BoxWrapper sd={10} ed={4} sm={5} em={3} ss={2} se={2}>
        <CardElement
          contact
          hicorange
          headline="Let´s design our future. Together."
          linktarget="/Hic"
          linktext="Contact Us"
        />
      </BoxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: transparent;
`

const BoxWrapper = styled(Row)`
  width: 100%;
`
