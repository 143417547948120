import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { Color, Headline, Paragraph } from '../styles/Variables'
import { LogoLarge } from '../assets/Icons'

export default function Header() {
  return (
    <Wrapper>
      <HeaderImage
        sd={1}
        ed={5}
        sm={1}
        em={9}
        ss={1}
        es={6}></HeaderImage>
      <HeaderHeadline sd={8} ed={6} sm={2} em={7} ss={2} se={3}>
        <Logo>{LogoLarge}</Logo>
        <H1>driving change in health technology</H1>
        <P>Let's design for our future. Together.</P>
        <Button href="#facts">Learn More</Button>
      </HeaderHeadline>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: ${Color.hicnight};
  background-image: url(/assets/images/header-bg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 60% calc(100% - 50px);
  @media ${props => props.theme.breakpoints.m} {
    background-size: auto calc(50% - 50px);
  }
  @media ${props => props.theme.breakpoints.s} {
      background-size: auto calc(30% - 50px);
  }
  
`

const HeaderImage = styled(Row)`
  height: 100vh;
  @media ${props => props.theme.breakpoints.m} {
    height: 50vh;
  }
  @media ${props => props.theme.breakpoints.s} {
    height: 50vh;
  }
  background-image: url('./assets/headerimage.jpg');
  background-size: cover;
  background-position: 70% top;
`

const Logo = styled.div`
  margin-bottom: 10rem;
  @media ${props => props.theme.breakpoints.m} {
    transform: translateX(0);
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.breakpoints.s} {
    margin-bottom: 2rem;
  }
  height: 200px;
  transform: translateX(-50%);
  width: fit-content;
`

const HeaderHeadline = styled(Row)`
  margin: 8rem 0 0 0;
 
  @media ${props => props.theme.breakpoints.m} {
    margin: 2rem 0;
    padding-bottom: 2rem;
    min-height: 50vh;
  }
  @media ${props => props.theme.breakpoints.s} {
    padding-bottom: 2rem;
    margin: 2rem 0;
    min-height: 50vh;
  }
`

const H1 = styled.h1`
  ${Headline.H1};
  color: ${Color.hicafterwork};
  text-transform: uppercase;
  margin: 0 0 2rem 0;
  padding: 0;
  @media ${props => props.theme.breakpoints.s} {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0 0 1rem 0;
  }
`

const P = styled.p`
  ${Paragraph.Large};
  font-style: italic;
  color: ${Color.hicearth};
  margin: 0 0 5rem 0;
  padding: 0;
`

const Button = styled.a`
  cursor: pointer;
  border: 2px solid ${Color.hicdaymedium};
  border-radius: 10px;
  padding: 1.375rem 1.25rem 1.375rem 1.25rem;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 2rem 0;
  transition: 0.1s ease-in-out;
  &:hover {
    background: ${Color.hicafterwork};
    color: ${Color.hicnight};
  }
`
