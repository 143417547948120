import styled from 'styled-components/macro'

export default function Img(props) {
  return <ImgStyled {...props} alt="" src={props.target} />
}

const ImgStyled = styled.img`
  width: ${props =>
    props.portrait ? '150px' : props.fullwidth ? '100%' : '50%'};
  min-height: ${props => (props.portrait ? '150px' : 'auto')};
  max-height: ${props =>
    props.portrait ? '220px' : props.fullwidth ? '100%' : 'auto'};
  border-radius: 0;
  object-fit: cover;
  float: ${props =>
    props.left
      ? 'left'
      : props.right
      ? 'right'
      : props.portrait
      ? 'right'
      : 'right'};
  margin: ${props =>
    props.left
      ? '0 1rem 1rem 0'
      : props.right
      ? '0 0 1rem 1rem'
      : props.fullwidth
      ? '3rem auto'
      : '0 0 1rem 1rem'};
`
