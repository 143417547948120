import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function AuditoryAcoustics() {
  useEffect(() => {
    document.title = 'Auditory Acoustics'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="Auditory Acoustics"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>AUDITORY ACOUSTICS - STMD-Evaluation</H2>
      <PSmall>
        <Img
          left
          target="./assets/images/auditoryacoustics/auditoryacoustics1.png"
        />
        The fitting of hearing aids to the individual hearing-impaired
        person is a time-consuming and therefore also costly process.
        The research project STMD-Evaluation investigates a novel
        audiologic measurement method, the Spectro-Temporal Modulation
        Detection, which has the potential to provide important
        information about the individual’s hearing impairment to
        simplify and accelerate this process.
      </PSmall>
      <PSmall>
        <Img
          right
          target="./assets/images/auditoryacoustics/auditoryacoustics2.png"
        />
        Researchers from an interdisciplinary project at the
        University of Lübeck are currently working on the development
        of a new form of a food sensor. This sensor can measure the
        amine content and could warn consumers of food products
        containing high amounts of amines. The sensor consists of a
        foil that changes its color. If the sensor foil is exposed to
        amines, the foil of the sensor changes color from green (low
        amine, eatable) to red (high amine, do not eat if allergic).
        The development of this system is based on the joint work of
        Dr. Christian Schell from Porphyrin Laboratories GmbH with PD
        Dr. Ramtin Rahmanzadeh, from the Institute for Biomedical
        Optics and Prof. Christian Sina, Director of the Institute for
        Nutritional Medicine.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/auditoryacoustics/auditoryacoustics3.png"
          />
          Prof. Dr. rer. nat Dipl. Phys. Tim Jürgens
          <br />
          Institute for Auditory Acoustics
          <br />
          <a href="tel:+49 451 300 5261">+49 451 300 5261</a>
          <br />
          <a href="mailto:tim.juergens@th-luebeck.de">
            tim.juergens@th-luebeck.de
          </a>
          <br />
          Technische Hochschule Lübeck
          <br />
          Mönkhofer Weg 239
          <br />
          Haus 17
          <br />
          23562 Lübeck
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
