import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Video from '../components/Video'
import Logo from '../elements/Logo'

export default function AICenter() {
  useEffect(() => {
    document.title = 'AI Center Lübeck'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="AI Center Lübeck"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>AI CENTER LÜBECK</H2>
      <H3>Center of Artificial Intelligence Lübeck (ZKIL)</H3>
      <PSmall>
        The Center for Artificial Intelligence Lübeck supports local
        AI activities in a variety of ways.
      </PSmall>
      <PSmall>
        The ZKIL promotes and coordinates the implementation of joint
        research projects of the participating institutes and clinics
        on all socially and economically relevant topics of artificial
        intelligence and supports the joint acquisition of third-party
        funds for this purpose.
      </PSmall>
      <PSmall>
        The ZKIL applies for and uses common resources such as large
        equipment and laboratories. It supports and finances the
        establishment of working groups that serve to develop
        opportunities for cooperation between the institutes and
        clinics involved.
      </PSmall>
      <PSmall>
        In the field of artificial intelligence, the ZKIL actively
        participates in the promotion of young scientists and the
        introduction of research results into the courses at the
        University of Lübeck. Suitable advanced training courses are
        developed for doctoral candidates. This also applies to
        ethical, legal and social aspects of artificial intelligence.
      </PSmall>
      <Video videotarget="https://www.youtube.com/embed/npwy7Qv3b9I" />
      <PSmall>
        The ZKIL operates an active public relations work. It provides
        regular information on the goals and results of research
        projects and represents the topic of artificial intelligence
        in relation to research policy and research funding
        institutions.
      </PSmall>
      <PSmall>
        The ZKIL promotes the transfer of knowledge and scientific
        communication by holding colloquia and lecture series as well
        as symposia and scientific congresses.
      </PSmall>
      <PSmall>
        The ZKIL promotes the transfer of basic research results.
        Cooperation with other research institutions and companies is
        possible for this purpose.
      </PSmall>
      <PSmall>
        Projects: KI-SIGS /KI-Lab / NEUPA / KI-Transfer-Hub /
        Mittelstand 4.0 / Research Unit Data Linking
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/aicenter/martinetz.jpg"
          />
          Prof. Dr. rer. nat. Thomas Martinetz
          <br />
          Institute for Neuro- and Bioinformatics
        </Li>
        <Li>
          Ratzeburger Allee 160 (Geb. 64)
          <br />
          23538 Lübeck
          <br />
          +49 451 3101 5500
          <br />
          <a href="mailto:martinetz@inb.uni-luebeck.de">
            martinetz@inb.uni-luebeck.de
          </a>
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.zkil.uni-luebeck.de/zkil/">
            https://www.zkil.uni-luebeck.de/zkil/
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
