import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { ShortArrow, LinkIcon } from '../assets/Icons'
import { Color } from '../styles/Variables'
import { Link } from 'react-router-dom'

export default function BreandcumbAndLink(props) {
  return (
    <Wrapper>
      <Row sd={5} ed={6} sm={2} em={4} ss={2} se={2}>
        <Text>You're here:</Text>
        <LocationBread>
          <ABread to="/">HIC Lübeck</ABread> {ShortArrow}{' '}
          {props.project}
        </LocationBread>
      </Row>
      <RowLinks sd={2} ed={2} sm={2} em={6} ss={2} se={2}>
        {props.link1target ? <Text>Read More:</Text> : null}
        <Location>
          {props.link1target ? (
            <A target="_blank" href={props.link1target}>
              {props.link1name} {LinkIcon}
            </A>
          ) : null}
          {props.link2target ? (
            <A target="_blank" href={props.link2target}>
              {props.link2name} {LinkIcon}
            </A>
          ) : null}
          {props.link3target ? (
            <A target="_blank" href={props.link3target}>
              {props.link3name} {LinkIcon}
            </A>
          ) : null}
          {props.link4target ? (
            <A target="_blank" href={props.link4target}>
              {props.link4name} {LinkIcon}
            </A>
          ) : null}
          {props.link5target ? (
            <A target="_blank" href={props.link5target}>
              {props.link5name} {LinkIcon}
            </A>
          ) : null}
          {props.link6target ? (
            <A target="_blank" href={props.link6target}>
              {props.link6name} {LinkIcon}
            </A>
          ) : null}
        </Location>
        {props.logoPath ? <CardLogo src={props.logoPath} /> : null}
      </RowLinks>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 6rem 0 0 0;
  position: relative;
`

const CardLogo = styled.img`
  margin: 25px 0 0;
  max-width: 250px;
`

const RowLinks = styled(Row)`
  position: absolute;
  width: 250px;
  @media ${props => props.theme.breakpoints.m} {
    position: relative;
    width: auto;
  }
`

const Text = styled(Row)`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${Color.hicnight};
  margin: 0 0 0.5rem 0;
`

const A = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-decoration: 2px solid underline;
  color: ${Color.hicnight};
  :after {
    content: ${LinkIcon};
  }
`

const ABread = styled(Link)`
  font-weight: 700;
  font-size: 1.25rem;
  text-decoration: 2px solid underline;
  color: ${Color.hicnight};
`

const Location = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 800;
  color: ${Color.hicdaymedium};
`

const LocationBread = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: 800;
  color: ${Color.hicdaymedium};
`
