import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function FabLab() {
  useEffect(() => {
    document.title = 'FRUBEEN'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="FRUBEEN"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>FRUBEEN</H2>
      <H3>effective treatment of malnutrition</H3>
      <PSmall>
        Frubeen is a Lübeck start-up that has its origins in the
        nutritional medicine course and was founded as part of an
        EXIST scholarship from the Institute for Nutritional Medicine
        of theUniversity Medical Center Schleswig-Holstein (UKSH). We
        strive to develop science-based nutritional solutions to
        emerging health issues. Our current goal is to provide an
        innovative oral nutrition for the effective treatment of
        malnutrition, a medical problem on par with obesity.
      </PSmall>
      <PSmall>
        <Img fullwidth target="./assets/images/frubeen/image.jpg" />
        Prof. Christian Sina, Bengt Scharfschwerdt-Otto and Prof.
        Martin Smollich present Frubeen at the Institute for
        Nutritional Medicine of the University Medical Center
        Schleswig-Holstein (UKSH); f.l.t.r.
      </PSmall>

      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/frubeen/portrait.jpg"
          />
          Bengt Scharfschwerdt-Otto
          <br />
          Founder of Frubeen
          <br />
          <br />
          Frubeen GmbH
          <br />
          <a href="tel:+49 152 267 02 125">+49 152 267 02 125</a>
          <br />
          <a href="mailto:info@frubeen.de">info@frubeen.de</a>
          <br />
          <a href="https://www.frubeen.dewww.frubeen.de">
            www.frubeen.de"
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
