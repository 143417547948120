import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function AIBSP() {
  useEffect(() => {
    document.title = 'AIBSP'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="AIBSP"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>Artificial Intelligence in Biomedical Signal Processing</H2>
      <H3>
        Hand Gesture Recognition for Prosthesis Control and
        Rehabilitation
      </H3>
      <PSmall>
        In our day-to-day lives our hands are of major importance. An
        impairment or even the loss of a hand have a significant
        impact on life quality. We use biosignals and AI to robustly
        recognize hand gestures even of impaired or amputated
        patients. In prostheses the AI allows for an intuitive
        control. For paralyzed patients the gesture recognition
        enables a biofeedback rehabilitation system providing users
        with visual feedback in a virtual environment.
        <Img fullwidth target="./assets/images/aibsp/aibsp1.jpg" />
      </PSmall>
      <H3>AI for Diagnostics</H3>
      <PSmall>
        <Img left target="./assets/images/aibsp/aibsp2.jpg" />
        AI for Diagnostics Multiple projects at the DFKI aim towards
        improvement of diagnostic procedures and reduction of
        clinicians’ workload. Sleep Staging is a key tool for the
        diagnosis of sleep disorders. To analyze the EEG data of a
        whole night, the deployment of an AI is desirable. The
        developed AI allows for a trustworthy analysis across subjects
        and even data acquisition systems, and when adapted to a given
        subject, the AI achieves an even higher accuracy.
      </PSmall>
      <PSmall>
        <Img right target="./assets/images/aibsp/aibsp3.jpg" />
        Arguably, auscultation and ECG are most relevant for a fast
        assessment of the heart. We develop AI solutions which run
        embedded and automatically classify diseases on the fly or
        improve the interpretability. Also, AI improved auscultation
        can also help assessing the condition of lung and the
        gastrointestinal system.
      </PSmall>
      <H3>Acoustic Signal Processing</H3>
      <PSmall>
        We work on AI based acoustic event detection, source
        localization, source separation, denoising, scene
        classification, and customization of hearing devices.
        <Img fullwidth target="./assets/images/aibsp/aibsp4.jpg" />
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Img portrait target="./assets/images/aibsp/portrait.jpg" />
        <Li>
          Philipp Koch
          <br />
          <a href="mailto:philipp.koch@dfki.de">
            philipp.koch@dfki.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
