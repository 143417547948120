import styled from 'styled-components/macro'
import Grid from '../layout/Grid'
import Row from '../layout/Row'
import { Color, Headline } from '../styles/Variables'

export default function SectionHeadline({
  sectionheadline
}) {
  return (
    <Wrapper as="div">
      <H2Styled as="h2" sd={2} ed={12} sm={2} em={6} ss={2} se={2}>
        {sectionheadline}
      </H2Styled>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin: 8.375rem auto 5.625rem;
`

const H2Styled = styled(Row)`
  ${Headline.H2};
  margin: 0;
  padding: 0;
  color: ${Color.hicnight};
  text-align: center;
  text-transform: uppercase;
`

const PStyled = styled(Row)`
  ${Headline.H4};
  margin: 1rem 0 0 0;
  padding: 0%;
  color: ${Color.hicday};
  text-align: center;
`
