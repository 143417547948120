import styled from 'styled-components/macro'
import { Color, Paragraph } from '../styles/Variables'

export default function Li({ children }) {
  return <LiStyled>{children}</LiStyled>
}

const LiStyled = styled.li`
  ${Paragraph.Small};
  margin: 0 0 1rem 0;
  padding: 0;
  color: ${Color.hicnight};
  text-align: left;
  list-style: none;
  clear:both;
`
