import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function AmineSensor() {
  useEffect(() => {
    document.title = 'A novel Amine Sensor'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="A novel Amine Sensor"
        link1target=""
        link1name=""
        link2target=""
        link2name=""
        link3target=""
        link3name=""
        link4target=""
        link4name=""
      />
      <H2>A novel amine sensor for Histamine intolerance</H2>
      <PSmall>
        <Img
          left
          target="./assets/images/aminesensor/aminesensor1.png"
        />
        Is this fish still edible? Can I eat that cheese? Patients
        suffering from histamine intolerance (histaminoses) know these
        questions very well. High concentrations ofamines, especially
        histamines can lead to a severe allergic response. In these
        patients the activity of the enzyme diamine oxidase (DAO) is
        often reduced and histamine from food can only partially be
        degraded.
      </PSmall>
      <PSmall>
        Researchers from an interdisciplinary project at the
        University of Lübeck are currently working on the development
        of a new form of a food sensor. This sensor can measure the
        amine content and could warn consumers of food products
        containing high amounts of amines. The sensor consists of a
        foil that changes its color. If the sensor foil is exposed to
        amines, the foil of the sensor changes color from green (low
        amine, eatable) to red (high amine, do not eat if allergic).
        The development of this system is based on the joint work of
        Dr. Christian Schell from Porphyrin Laboratories GmbH with PD
        Dr. Ramtin Rahmanzadeh, from the Institute for Biomedical
        Optics and Prof. Christian Sina, Director of the Institute for
        Nutritional Medicine.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/aminesensor/aminesensor2.jpg"
          />
          PD Dr. Ramtin Rahmanzadeh
          <br />
          Universität zu Lübeck
          <br />
          Institute for Biomedical Optics
          <br />
          phone <a href="tel:+49 451 3101-3210">+49 451 3101-3210</a>
          <br />
          E-Mail:{' '}
          <a href="mailto:ramtin.rahmanzadeh@uni-luebeck.de">
            ramtin.rahmanzadeh@uni-luebeck.de
          </a>
          <br />
          Web:{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bmo.uni-luebeck.de">
            www.bmo.uni-luebeck.de
          </a>
          <br />
          Peter-Monnik-Weg 4<br />
          23562 Lübeck
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
