import { useEffect } from 'react'
import styled from 'styled-components/macro'
import BreadcrumbAndLinks from '../components/BreadcrumbAndLinks'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Img from '../components/Img'
import Li from '../components/Li'
import PSmall from '../components/PSmall'
import Ul from '../components/Ul'
import Logo from '../elements/Logo'

export default function Mirana() {
  useEffect(() => {
    document.title = 'MIRANA'
  }, [])

  return (
    <Wrapper>
      <Logo />
      <BreadcrumbAndLinks
        project="MIRANA"
        link1name="Website"
        link1target="https://mirana.rob.uni-luebeck.de"
        link2name="YouTube: „Guiding People“"
        link2target="https://www.youtube.com/watch?v=q1YQZTLdIoQ"
        link3name="YouTube: „Logistical Scenario“"
        link3target="https://www.youtube.com/watch?v=EnBz7pjstr0"
        link4name=""
        link4target=""
      />
      <H2>MIRANA</H2>
      <H3>
        Mobile Intelligent Robot Agent for Navigation and Assistance
      </H3>
      <PSmall>
        Intelligent navigation and information services will make the
        visit in hospitals a better experience. In the MIRANA (Mobile
        Intelligent Robot Agent for Navigation and Assistance )
        project we are building software that enables robots to guide
        humans autonomously in the healthcare environment.
      </PSmall>
      <PSmall>
        There are three main applications for the final product:
        <br />
        1. Personal guide – guiding the patients/visitors from
        reception to the station, including special guiding for
        visually impaired people
        <br />
        2. Info point – providing patients/visitors information about
        hospital services upon request
        <br />
        3. Transporter – delivering small equipment, documents and
        materials among various stations{' '}
      </PSmall>
      <PSmall>
        We have started with the software solution for mapping &
        localization in hospital buildings, speaker tracking and
        speech recognition & synthesis. To that end, we are using the
        personal transporter robot from Segway Robotics and developing
        applications on the Segway Robot platform.
      </PSmall>
      <H3>Contact</H3>
      <Ul>
        <Li>
          <Img
            portrait
            target="./assets/images/mirana/Foto_FlorisErnst.png"
          />
          Prof. Dr. Floris Ernst
          <br />
          University of Lübeck
          <br />
          Institute for Robotics and Cognitive Systems
          <br />
          Ratzeburger Allee 160
          <br />
          23538 Lübeck
          <br />
          Phone number: +49 451 3101 5208
          <br />
          Fax number: +49 451 3101 5204
          <br />
          Email
          <a href="ernst@rob.uni-luebeck.de">
            ernst@rob.uni-luebeck.de
          </a>
        </Li>
      </Ul>
      <ContactUs />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``
